<template>
  <!--客户案例-->
  <div class="zj_customerCase">
    <div class="cnname font16rem black wow bounceInLeft"
    data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">{{$t('客户案例')}}</div>
    <div class="nameline wow bounceInLeft"
    data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
      <div class="line"></div>
    </div>
    <div :class="$i18n.locale == 'CN'?'serveSlide wow bounceInRight':'serveSlideEN wow bounceInRight'"
     data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
      <div class="swiper-container customerCase_container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="item in casedata" :key="item.index">
            <div class="slide_content">
              <div class="spic">
                <img :src="item.pic" />
              </div>
              <div class="ss s1 blue1 font30rem fontweight flextbc">"</div>
              <div class="ss s2 blue1 font16rem fontweight flextbc">
                {{ item.name }}
              </div>
              <div class="ss s3 font10rem fontweight flextbc">
                {{ item.detail1 }}
              </div>
              <div class="ss s4 font10rem flextbc black">
                {{ item.detail2 }}
              </div>
              <div class="ss s5 font10rem black2">
                {{ item.detail3 }}
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-button-prev"></div>
        <!--左箭头。如果放置在swiper-container外面，需要自定义样式。-->
        <div class="swiper-button-next"></div>
        <!--右箭头。如果放置在swiper-container外面，需要自定义样式。-->
      </div>
    </div>
  </div>
</template>

<script>
//import {WOW} from 'wowjs'
import Swiper from "swiper";
export default {
  name: "zj_customerCase",
  data() {
    return {
      casedata: [
        // {
        //   index: 1,
        //   name: "波司登",
        //   pic: require("../assets/Images/custom/bsdbg.png"),
        //   detail1: "魔元术新零售一体化系统",
        //   detail2: "门店价格策略/管控万店千面",
        //   detail3:
        //     "POS+（离线POS）、移动BI业务数据中台、HR（终端入离调转）、扫码购小程序、SAP平台无缝衔接",
        // },
        {
          index: 1,
          name:this.$t( "晨光九木杂物社"),
          pic: require("../assets/Images/custom/jmzws.jpg"),
          detail1:this.$t("魔元术新零售一体化系统") ,
          detail2:this.$t( "零售、批发、电商一体化"),
          detail3:
             this.$t( "POS、DRP、O2O订单路由、电商EB含OMS、移动BI等"),
        },
        {
          index: 2,
          name: "ANTA",
          pic: require("../assets/Images/custom/antabg.png"),
          detail1:this.$t("魔元术新零售一体化系统") ,
          detail2: this.$t("多事业部/多品牌/多财务核算体"),
          detail3:
           this.$t( "终端POS、终端HR（考勤管理）、DRP、WMS、CRM（KK）、移动BI（作战监控）、EB含OMSDST&KL）"),
        },
        {
          index: 3,
          name:this.$t("太平鸟") ,
          pic: require("../assets/Images/custom/tpnbg.png"),
          detail1:this.$t("魔元术新零售一体化系统") ,
          detail2: "",
          detail3:this.$t( "多种形式POS、DRP、OMS、CRM、移动BI、新零售数据中台"),
        },
      ],
    };
  },
  watch: {
     '$i18n.locale': function (newVal) { 
         this.casedata=[
        // {
        //   index: 1,
        //   name: "波司登",
        //   pic: require("../assets/Images/custom/bsdbg.png"),
        //   detail1: "魔元术新零售一体化系统",
        //   detail2: "门店价格策略/管控万店千面",
        //   detail3:
        //     "POS+（离线POS）、移动BI业务数据中台、HR（终端入离调转）、扫码购小程序、SAP平台无缝衔接",
        // },
        {
          index: 1,
          name:this.$t( "晨光九木杂物社"),
          pic: require("../assets/Images/custom/jmzws.jpg"),
          detail1:this.$t("魔元术新零售一体化系统") ,
          detail2:this.$t( "零售、批发、电商一体化"),
          detail3:
             this.$t( "POS、DRP、O2O订单路由、电商EB含OMS、移动BI等"),
        },
        {
          index: 2,
          name: "ANTA",
          pic: require("../assets/Images/custom/antabg.png"),
          detail1:this.$t("魔元术新零售一体化系统") ,
          detail2: this.$t("多事业部/多品牌/多财务核算体"),
          detail3:
           this.$t( "终端POS、终端HR（考勤管理）、DRP、WMS、CRM（KK）、移动BI（作战监控）、EB含OMSDST&KL）"),
        },
        {
          index: 3,
          name:this.$t("太平鸟") ,
          pic: require("../assets/Images/custom/tpnbg.png"),
          detail1:this.$t("魔元术新零售一体化系统") ,
          detail2: "",
          detail3:this.$t( "多种形式POS、DRP、OMS、CRM、移动BI、新零售数据中台"),
        },
      ]
     }
  },
  mounted() {
    new this.$wow.WOW({ live: false }).init();
    this.swiperCase();
  },
  methods: {
    swiperCase() {
      let that = this;
      let swiper1 = new Swiper(".customerCase_container", {
        direction: "horizontal",
        autoplay:3000,
        // autoplay: {
        //   delay: 3000,
        //   // stopOnLastSlide: false,
        //   disableOnInteraction: false,
        // },
        speed: 1000,
        autoplayDisableOnInteraction: false,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        loop: true,
        pagination: ".swiper-pagination",
        paginationClickable: true,
        grabCursor: true, //鼠标光标
        nextButton: ".swiper-button-next",
        prevButton: ".swiper-button-prev",
        slidesPerView: 3,
        centeredSlides: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.zj_customerCase {
  width: 100%;
  height: 100%;
  margin-top: 6rem;
  .cnname {
    width: 70%;
    margin-left: 15%;
    margin-bottom: 1rem;
  }
  .nameline {
    width: 70%;
    margin-left: 15%;
    margin-bottom: 1rem;
    .line {
      width: 8rem;
      height: 0.3rem;
      background-color: #2473e3;
      border-radius: 10px;
    }
  }
  .serveSlide {
    width: 100%;
    height: 80%;
    background-image: url("../assets/Images/casebg.png");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 100% 70%;
    .customerCase_container {
      width: 80%;
      margin-left: 10%;
      height: 100%;
      .swiper-button-prev:after,
      .swiper-button-next:after {
        font-size: 2rem;
      }
      // .swiper-button-prev,
      // .swiper-container-rtl .swiper-button-next,
      // .swiper-button-next,
      // .swiper-container-rtl .swiper-button-prev {
      //   background-color: #2473e3;
      //   color: #fff;
      //   border-radius: 0.3rem;
      //   padding: 0.5rem 0.5rem;
      //   box-shadow: 0 0 10px rgba(36, 115, 227, 0.61);
      //   width: 2%;
      //   height: 3rem;
      // }

      .swiper-button-prev,
      .swiper-button-next {
        color: #e7ebf8;
        background-image:none!important;
      }
      .swiper-slide-active .slide_content {
        transform: scale(1.4);
        background-color: #2473e3;
        .s1,
        .s2,
        .s3,
        .s4,
        .s5 {
          color: #fff;
        }
        .s1,
        .s2,
        .s3,
        .s4 {
          height: 6%;
        }
        .s3,
        .s4,
        .s5 {
          font-size: 0.7rem;
        }
        .spic {
          height: 60%;
          display: block;
        }
      }
      .swiper-slide {
        margin-top: 0.5rem;
      }
    }
    .slide_content {
      width: 60%;
      margin: 20% auto;
      height: 60%;
      // padding-top: 1.5rem;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(36, 115, 227, 0.61);
      .ss {
        width: 80%;
        margin: 0 auto;
      }
      .spic {
        height: 0%;
        display: none;
        img {
          width: 100%;
        }
      }
      .s1 {
        height: 25%;
      }
      .s2 {
        height: 10%;
      }
      .s3 {
        height: 10%;
      }
      .s4 {
        height: 10%;
        margin-bottom: 3%;
      }
      .s5 {
        height: 40%;
      }
    }
  }
  .serveSlideEN {
    width: 100%;
    height: 80%;
    background-image: url("../assets/Images/casebg.png");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 100% 70%;
    .customerCase_container {
      width: 80%;
      margin-left: 10%;
      height: 100%;
      .swiper-button-prev:after,
      .swiper-button-next:after {
        font-size: 2rem;
      }
      // .swiper-button-prev,
      // .swiper-container-rtl .swiper-button-next,
      // .swiper-button-next,
      // .swiper-container-rtl .swiper-button-prev {
      //   background-color: #2473e3;
      //   color: #fff;
      //   border-radius: 0.3rem;
      //   padding: 0.5rem 0.5rem;
      //   box-shadow: 0 0 10px rgba(36, 115, 227, 0.61);
      //   width: 2%;
      //   height: 3rem;
      // }

      .swiper-button-prev,
      .swiper-button-next {
        color: #e7ebf8;
        background-image:none!important;
      }
      .swiper-slide-active .slide_content {
        transform: scale(1.4);
        background-color: #2473e3;
        .s1,
        .s2,
        .s3,
        .s4,
        .s5 {
          color: #fff;
        }
        .s1,
        .s2,
        .s3,
        .s4 {
          height: 6%;
        }
        .s3,
        .s4,
        .s5 {
          font-size: 0.7rem;
        }
        .spic {
          height: 60%;
          display: block;
        }
      }
      .swiper-slide {
        // margin-top: 0.5rem;
      }
    }
    .slide_content {
      width: 63%;
      margin: 20% auto;
      height: 64%;
      // padding-top: 1.5rem;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(36, 115, 227, 0.61);
      .ss {
        width: 80%;
        margin: 0 auto;
      }
      .spic {
        height: 0%;
        display: none;
        img {
          width: 100%;
        }
      }
      .s1 {
        height: 19%;
      }
      .s2 {
        height: 10%;
      }
      .s3 {
        height: 10%;
        line-height: 0.8rem;
      }
      .s4 {
        height: 10%;
        margin-bottom: 3%;
      }
      .s5 {
        height: 40%;
        text-align: left;
        // line-height: 1rem;
      }
    }
  }
}
</style>
