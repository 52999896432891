<template>
  <!--业务范围-->
  <div class="zj_serviceArea">
    <div class="cnname font16rem black wow bounceInLeft"
    data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">{{$t('业务范围')}}</div>
    <div class="nameline wow bounceInLeft"
    data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
      <div class="line"></div>
    </div>
    <div class="serveSlide wow bounceInRight"
    data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
      <div class="swiper-container serviceArea_container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="item in data" :key="item.index">
            <div class="slide_content">
              <div class="slide_detail flexcc">
                <img :class="`img1` + item.index" :src="item.pic" />
                <img
                  class="hidden"
                  :class="`img2` + item.index"
                  :src="item.pic2"
                />
              </div>
              <div :class="$i18n.locale == 'CN'?'slide_name black flexcc font12rem':'slide_nameEN black flexcc font12rem'">
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-button-prev"></div>
        <!--左箭头。如果放置在swiper-container外面，需要自定义样式。-->
        <div class="swiper-button-next"></div>
        <!--右箭头。如果放置在swiper-container外面，需要自定义样式。-->
      </div>
    </div>
  </div>
</template>

<script>
//import {WOW} from 'wowjs'
import Swiper from "swiper";
export default {
  name: "zj_serviceArea",
  data() {
    return {
      data: [
        {
          index: 1,
          name:this.$t( "新零售一体化"),
          pic: require("../assets/Images/yth1.png"),
          pic2: require("../assets/Images/yth2.png"),
        },
        {
          index: 2,
          name: this.$t("快速开发工具"),
          pic: require("../assets/Images/kskf1.png"),
          pic2: require("../assets/Images/kskf2.png"),
        },
        {
          index: 3,
          name:this.$t("智慧数据中台") ,
          pic: require("../assets/Images/sjzt1.png"),
          pic2: require("../assets/Images/sjzt2.png"),
        },
        {
          index: 4,
          name:this.$t("全渠道营销") ,
          pic: require("../assets/Images/qqd1.png"),
          pic2: require("../assets/Images/qqd2.png"),
        },
        {
          index: 5,
          name:this.$t("私域流量管理") ,
          pic: require("../assets/Images/syll_wxz.png"),
          pic2: require("../assets/Images/syll_xz.png"),
        },
        {
          index: 6,
          name:this.$t("全渠道订单路由") ,
          pic: require("../assets/Images/ddly_wxz.png"),
          pic2: require("../assets/Images/ddly_xz.png"),
        },
      ],
    };
  },
  watch: {},
  mounted() {
    new this.$wow.WOW({ live: false }).init();
    this.changeClass();
    this.swiperArea();
  },
  methods: {
    changeClass() {
      $(".img11").addClass("hidden").siblings().removeClass("hidden");
      $(".img22").addClass("hidden").siblings().removeClass("hidden");
      $(".img23").addClass("hidden").siblings().removeClass("hidden");
      $(".img24").addClass("hidden").siblings().removeClass("hidden");
      $(".img25").addClass("hidden").siblings().removeClass("hidden");
      $(".img26").addClass("hidden").siblings().removeClass("hidden");
    },
    swiperArea() {
      let that = this;
      let swiper1 = new Swiper(".serviceArea_container", {
        direction: "horizontal",
        speed: 1000,
        autoplay : 3000,
        autoplayDisableOnInteraction : false,
        // autoplay: {
        //   delay: 3000,
        //   // stopOnLastSlide: false,
        //   disableOnInteraction: false,
        // },
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        loop: true,
        pagination: ".swiper-pagination",
        paginationClickable: true,
        grabCursor: true, //鼠标光标
        nextButton: ".swiper-button-next",
        prevButton: ".swiper-button-prev",
        slidesPerView: 4,
        //spaceBetween : 40,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        onSlideChangeStart: function(swiper){
        //console.log(swiper.activeIndex);
            if (swiper.activeIndex == 4 || swiper.activeIndex == 10) {
              $(".img11").addClass("hidden").siblings().removeClass("hidden");
              $(".img22").addClass("hidden").siblings().removeClass("hidden");
              $(".img23").addClass("hidden").siblings().removeClass("hidden");
              $(".img24").addClass("hidden").siblings().removeClass("hidden");
              $(".img25").addClass("hidden").siblings().removeClass("hidden");
              $(".img26").addClass("hidden").siblings().removeClass("hidden");
            } else if (swiper.activeIndex == 5) {
              $(".img12").addClass("hidden").siblings().removeClass("hidden");
              $(".img21").addClass("hidden").siblings().removeClass("hidden");
              $(".img23").addClass("hidden").siblings().removeClass("hidden");
              $(".img24").addClass("hidden").siblings().removeClass("hidden");
              $(".img25").addClass("hidden").siblings().removeClass("hidden");
              $(".img26").addClass("hidden").siblings().removeClass("hidden");
            } else if (swiper.activeIndex == 6) {
              $(".img13").addClass("hidden").siblings().removeClass("hidden");
              $(".img22").addClass("hidden").siblings().removeClass("hidden");
              $(".img21").addClass("hidden").siblings().removeClass("hidden");
              $(".img24").addClass("hidden").siblings().removeClass("hidden");
              $(".img25").addClass("hidden").siblings().removeClass("hidden");
              $(".img26").addClass("hidden").siblings().removeClass("hidden");
            } else if (swiper.activeIndex == 7) {
              $(".img14").addClass("hidden").siblings().removeClass("hidden");
              $(".img22").addClass("hidden").siblings().removeClass("hidden");
              $(".img23").addClass("hidden").siblings().removeClass("hidden");
              $(".img21").addClass("hidden").siblings().removeClass("hidden");
              $(".img25").addClass("hidden").siblings().removeClass("hidden");
              $(".img26").addClass("hidden").siblings().removeClass("hidden");
            } else if (swiper.activeIndex == 8) {
              $(".img21").addClass("hidden").siblings().removeClass("hidden");
              $(".img22").addClass("hidden").siblings().removeClass("hidden");
              $(".img23").addClass("hidden").siblings().removeClass("hidden");
              $(".img24").addClass("hidden").siblings().removeClass("hidden");
              $(".img15").addClass("hidden").siblings().removeClass("hidden");
              $(".img26").addClass("hidden").siblings().removeClass("hidden");
            } else if (swiper.activeIndex == 9) {
              $(".img21").addClass("hidden").siblings().removeClass("hidden");
              $(".img22").addClass("hidden").siblings().removeClass("hidden");
              $(".img23").addClass("hidden").siblings().removeClass("hidden");
              $(".img24").addClass("hidden").siblings().removeClass("hidden");
              $(".img25").addClass("hidden").siblings().removeClass("hidden");
              $(".img16").addClass("hidden").siblings().removeClass("hidden");
            }
    },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.zj_serviceArea {
  width: 70%;
  height: 100%;
  margin-left: 15%;
  margin-top: 3rem;
  .cnname {
    width: 100%;
    margin-bottom: 1rem;
  }
  .nameline {
    width: 100%;
    margin-bottom: 1rem;
    .line {
      width: 8rem;
      height: 0.3rem;
      background-color: #2473e3;
      border-radius: 10px;
    }
  }
  .serveSlide {
    width: 100%;
    height: 55%;
    margin: 8% 0;
    .serviceArea_container {
      width: 100%;
      height: 100%;
      .swiper-wrapper {
        width: 90%;
        .swiper-slide {
          margin-left: 0.3rem;
        }
      }
      .swiper-button-prev:after,
      .swiper-button-next:after {
        font-size: 2rem;
      }
      // }
      // .swiper-button-prev,
      // .swiper-container-rtl .swiper-button-next,
      // .swiper-button-next,
      // .swiper-container-rtl .swiper-button-prev {
      //   background-color: rgba(36, 115, 227,1);
      //   color: #fff;
      //   border-radius: 0.3rem;
      //   padding: 0.5rem 0.5rem;
      //   box-shadow: 0 0 10px rgba(36, 115, 227, 0.61);
      //   width: 2%;
      //   height: 3rem;
      // }

      .swiper-button-prev,
      .swiper-button-next {
        color: #e7ebf8;
        background-image:none!important;
      }
      .swiper-slide-active .slide_detail {
        background-color: #2473e3 !important;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(36, 115, 227, 0.61);
      }
    }
    .slide_content {
      width: 55%;
      margin: 0 0% 0 12%;
      //   margin-left:15%;
      height: 100%;
      padding-top: 1.5rem;
      .slide_detail {
        width: 100%;
        height: 70%;
        background-color: #f6f9ff;
        img {
          width: 40%;
        }
      }
      .slide_name {
        width: 100%;
        height: 30%;
      }
      .slide_nameEN {
        width: 120%;
        height: 20%;
      }
    }
  }
}
</style>
