<template>
<!--首页-->
  <div class="home">
    <div class="top">
      <top :selected="selected" :pic="pic"></top>
    </div>
    <div class="topIntroduce">
      <topIntroduce></topIntroduce>
    </div>
    <div class="homeContent">
      <div class="codata">
        <coData></coData>
      </div>
      <div class="aboutus">
        <aboutUs></aboutUs>
      </div>
      <div class="servicefeatures">
        <serviceFeatures></serviceFeatures>
      </div>
      <div class="servicearea">
        <serviceArea></serviceArea>
      </div>
      <div class="customercase">
        <customerCase></customerCase>
      </div>
      <div class="customerlogo">
        <customerLogo></customerLogo>
      </div>
      <div class="bottomIntroduce">
      <bottomIntroduce></bottomIntroduce>
    </div>
    </div>
    
  </div>
</template>

<script>
// @ is an alias to /src
import top from "@/components/top.vue";
import topIntroduce from "@/components/topIntroduce.vue";
import coData from "@/components/coData2.vue";
import aboutUs from "@/components/aboutUs.vue";
import serviceFeatures from "@/components/serviceFeatures.vue";
import serviceArea from "@/components/serviceArea.vue";
import customerCase from "@/components/customerCase.vue";
import customerLogo from "@/components/customerLogo.vue";
import bottomIntroduce from "@/components/bottomIntroduce.vue";

export default {
  name: "Home",
   data() {
    return {
      selected: "home",
      pic:require("../assets/Images/tbg1.png"),
    };
  },
  components: {
    top,
    topIntroduce,
    coData,
    aboutUs,
    serviceFeatures,
    serviceArea,
    customerCase,
    customerLogo,
    bottomIntroduce
  },
  methods: {},
  mounted() {},
};
</script>
<style lang="scss">
::-webkit-scrollbar-track-piece { //滚动条凹槽的颜色，还可以设置边框属性
background-color:#f8f8f8;
}
::-webkit-scrollbar {//滚动条的宽度
width:9px;
height:9px;
z-index: 999;
}
::-webkit-scrollbar-thumb {//滚动条的设置
background-color:#dddddd;
background-clip:padding-box;
min-height:28px;
-webkit-border-radius: 2em;
 -moz-border-radius: 2em;
 border-radius:2em;
}
::-webkit-scrollbar-thumb:hover {
background-color:#bbb;
}

// ::-webkit-scrollbar {display:none}
.home {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  .top{
    width: 100%;
    height: 3.5rem;
    position: absolute;
  z-index: 100;
  }
  .topIntroduce {
    width: 100%;
    height: 37.5rem;
  }
  .homeContent {
    width: 100%;
    //height: 100%;
    background-image: url("../assets/Images/bg.png");
    //background-position: 0%;
    background-repeat: repeat;
    background-size: 100%;
    .codata {
      width: 100%;
      height: 10rem;
      margin-bottom: 2rem;
    }
    .aboutus{
      width: 100%;
      height: 40rem;
      margin-top: 0;
    }
    .servicefeatures{
      width: 100%;
    }
    .servicearea{
      width: 100%;
      height: 30rem;
    }
    .customercase{
      width: 100%;
      height: 50rem;
    }
  }
}
</style>