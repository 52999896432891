import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue') //首页
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/Home.vue') //首页
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('@/views/aboutUs.vue') //关于我们
  },
  {
    path: '/custom',
    name: 'custom',
    component: () => import('@/views/custom.vue') //客户案例
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('@/views/product.vue') //产品展示
  },
  {
    path: '/productPass',
    name: 'product_tab1',
    component: () => import('@/views/product_tab1.vue') //快速开发平台
  },
  {
    path: '/productRetail',
    name: 'product',
    component: () => import('@/views/product_tab2.vue') //新零售一体化系统
  },
  {
    path: '/productPos',
    name: 'product',
    component: () => import('@/views/product_tab3.vue') //POS+智慧收银系统
  },
  {
    path: '/productDashboard',
    name: 'product',
    component: () => import('@/views/product_tab4.vue') //数据可视化大屏
  },
  {
    path: '/productBi',
    name: 'product',
    component: () => import('@/views/product_tab5.vue') //零售数字化移动BI
  },
  {
    path: '/productMarket',
    name: 'product',
    component: () => import('@/views/product_tab6.vue') //全渠道营销与私域流量管理
  },
  {
    path: '/dashboardDetail',
    name: 'dashboardDetail',
    component: () => import('@/views/dashboardDetail.vue') //全渠道营销与私域流量管理
  },
  {
    path: '/detialDesign',
    name: 'detialDesign',
    component: () => import('@/views/detialDesign.vue') //魔数智屏
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router