<template>
    <!--通用头部tab-->
    <div class="top">
        <div class="top_tip flexrow" :class="{ is_fixed: isFixed }">
            <div :class=" $i18n.locale == 'CN'?'tip_logo flexcc':'tip_logoEN flexcc'">
                <img
                    :src="
                        $i18n.locale == 'CN'
                            ? require('@/assets/Images/logo.png')
                            : require('@/assets/Images/logoEN.png')
                    "
                />
            </div>
            <div class="tip_info flexrow">
                <div class="tip_meun">
                    <div class="weui_tab flexcc">
                        <div class="ui_tab_area flexrow font8rem">
                            <div class="ui_tab ui_tabreport flexrow">
                                <div
                                    class="ui_tab_item"
                                    :class="{
                                        ui_tab_item_on: item.id == selected,
                                    }"
                                    v-for="item in timeData"
                                    :key="item.id"
                                    @click="toFather(item.id)"
                                >
                                    <span>{{ item.name }}</span>
                                </div>

                                <!-- <div class="ui_tab_item ui_tab_item_on" typeId="home" @click="tabContentSel('home',this)"><span>首页</span></div>
                            <div class="ui_tab_item" typeId="about" @click="tabContentSel('about',this)"><span>关于我们</span></div>
                            <div class="ui_tab_item" typeId="product" @click="tabContentSel('product',this)"><span>产品展示</span></div>
                            <div class="ui_tab_item" typeId="custom" @click="tabContentSel('custom',this)"><span>客户案例</span></div>
                            <div class="ui_tab_item" typeId="contact" @click="tabContentSel('contact',this)"><span>联系我们</span></div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tip_phone flexcc">
                    <div class="phone font8rem white flexcc din fontweight">
                        <img src="../assets/Images/phone2.png" />
                        &nbsp;&nbsp;{{ $t("4000-620-530") }}
                    </div>
                    <div class="dropdown" >
                        <img  class="dropbtn" src="../assets/Images/FY.png" />
                        <div id="dropdownContent" class="dropdown-content">
                            <a @click="changeLanguage('CN')" href="#">中文</a>
                            <a @click="changeLanguage('EN')" href="#">English</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import i18n from "vue-i18n";
export default {
    name: "topIntroduce",
    props: ["selected", "pic"],
    data() {
        return {
            srcImg: "../assets/Images/logo.png",
            command: localStorage.getItem("language"),
            timeData: [
                { id: "home", name: this.$t("首页") },
                { id: "aboutUs", name: this.$t("关于我们") },
                { id: "productPass", name: this.$t("产品展示") },
                { id: "custom", name: this.$t("客户案例") },
                //  { id: "contact", name: "联系我们" },
            ],
            // selected: "home",
            isFixed: true,
        };
    },
    mounted() {
        console.log(i18n, "jjj");
        //console.log("1111",this.pic)
        $(".is_fixed").css("background-image", "url(" + this.pic + ")");
        this.changeTab();
        // window.addEventListener("scroll", this.handleScroll); // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    },
    watch: {
        "$i18n.locale": function (newVal) {
            this.timeData = [
                { id: "home", name: this.$t("首页") },
                { id: "aboutUs", name: this.$t("关于我们") },
                { id: "productPass", name: this.$t("产品展示") },
                { id: "custom", name: this.$t("客户案例") },
                //  { id: "contact", name: "联系我们" },
            ];
        },
        pic(newval, oldval) {
            console.log("newval", newval);
            console.log("oldval", oldval);
            $(".is_fixed").css("background-image", "url(" + this.pic + ")");
            this.selected = newval;
        },
    },
    methods: {
        // 选择语言
        async changeLanguage(val) {
            // this.command =
            //     this.command == "CN"
            //         ? "EN"
            //         : this.command == "EN"
            //         ? "CN"
            //         : "CN";
            
            // console.log(val);
            this.command=val
            localStorage.setItem("language", this.command);
            this.$i18n.locale = this.command;
            this.$router.go(0)
            // location.reload()
        },
        tabContentSel(id, e) {
            console.log(id);
            $(e)
                .addClass("ui_tab_item_on")
                .siblings()
                .removeClass("ui_tab_item_on");
        },
        toFather(time) {
            console.log(time);
            this.$emit("fatherMethod", time);
            this.$router.push(time);
            if (time == "productPass") {
                localStorage.setItem("toId", 0);
                localStorage.setItem("topClick", "Y");
            }
            // var url = "../../"+time;
            // window.open(url, '_self');
        },
        changeTab() {
            $(".ui_tab_item").click(function () {
                $(this)
                    .addClass("ui_tab_item_on")
                    .siblings()
                    .removeClass("ui_tab_item_on");
            });
        },
        // handleScroll() {
        //   let scrollTop =
        //     window.pageYOffset ||
        //     document.documentElement.scrollTop ||
        //     document.body.scrollTop; // 滚动条偏移量
        //   let offsetTop = document.querySelector(".topIntroduce").offsetTop; // 要滚动到顶部吸附的元素的偏移量
        //   this.isFixed = scrollTop > offsetTop ? true : false; // 如果滚动到顶部了，this.isFixed就为true
        //  // console.log(11111);
        // },
    },
};
</script>

<style scoped lang="scss">
/* 下拉按钮样式 */
.dropbtn {
    // background-color: #4CAF50;
    // color: white;
    // padding: 16px;
    // font-size: 16px;
    // border: none;
    cursor: pointer;
}

/* 容器 <div> - 需要定位下拉内容 */
.dropdown {
    position: relative;
    display: inline-block;
}

/* 下拉内容 (默认隐藏) */
.dropdown-content {
    display: none;
    position: absolute;
    top: 30px;
    right:-40px;
    background-color: #f9f9f9;
    min-width: 70px;
    // margin-right: 10px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

/* 下拉菜单的链接 */
.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

/* 鼠标移上去后修改下拉菜单链接颜色 */
.dropdown-content a:hover {
    background-color: #f1f1f1;
}

/* 在鼠标移上去后显示下拉菜单 */
.dropdown:hover .dropdown-content {
    display: block;
}

/* 当下拉内容显示后修改下拉按钮的背景颜色 */
.dropdown:hover .dropbtn {
    // background-color: #3e8e41;
}
.is_fixed {
    position: fixed;
    // background-color: rgba(255, 255, 255, 0.1);
    //  background-image: url("../assets/Images/tbg1.png");
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 100%;
}
.top {
    width: 100%;
    height: 100%;
}
.top_tip {
    width: 100%;
    height: 3.5rem;
    position: fixed;
    z-index: 2;
    /*background-color: rgba(255,255,255,0.2);*/
}

.top_tip .tip_logo {
    width: 20%;
    height: 100%;
}

.tip_logo img {
    width: 40%;
}
.tip_logoEN img {
    width: 80%;
    height: 40%;
}
.top_tip .tip_info {
    width: 80%;
    height: 100%;
}

.top_tip .tip_info .tip_meun {
    width: 78%;
    height: 100%;
}

.top_tip .tip_info .tip_meun .weui_tab {
    width: 60%;
    height: 100%;
    margin-left: 40%;
}

.top_tip .tip_info .tip_meun .ui_tab_area {
    margin: 0 auto;
    width: 90%;
}
.top_tip .tip_info .tip_meun .ui_tabreport {
    width: 100%;
    height: 100%;
}
.top_tip .tip_info .tip_meun .ui_tab_item {
    position: relative;
    display: block;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    flex: 1;
    padding: 10px 0 0 0;
    margin-bottom: 10px;
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
}
.top_tip .tip_info .tip_meun .ui_tab_item_on span {
    color: #fff;
    padding-bottom: 3px;
    border-bottom: 1px solid #fff;
}

.top_tip .tip_info .tip_phone {
    width: 20%;
    height: 100%;
    img {
        width: 13%;
        margin-right: 4%;
    }
}
.top_tip .tip_info .dropdown {
    //   width: 20%;
    //   height: 100%;
    position: relative;
    display: inline-block;
    cursor: pointer;
    img {
        width: 70%;
        margin-left: 50%;
        margin-top: 15%;
    }
}

.top_tip .tip_info .tip_phone .phone {
    width: 11rem;
    /*height: 50%;*/
    border: 1px solid #fff;
    border-radius: 50px;
    padding: 0.1rem 0;
}
</style>
