<template>
<!--特色服务-->
  <div class="zj_serviceFeatures">
    <div class="cnname flexcc font16rem black wow bounceIn" 
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">{{$t('特色服务')}}</div>
    <div class="enname flexcc font16rem black wow bounceIn" 
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">Service Features</div>
    <div class="nameline flexcc wow bounceIn" 
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
      <div class="line"></div>
    </div>
    <div class="fivenew wow bounceInDown"
    data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
      <div class="swiper-container fivenew_container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="item in sfData" :key="item.index">
            <div class="sbg">
              <div class="newpic flexcc newpic1">
                <img :src="item.icon" />
              </div>
              <div class="newpic flexcc newpic2 hidden">
                <img :src="item.pic" />
              </div>
              <div class="newname flexcc font12rem black">{{ item.name }}</div>
              <div :class="$i18n.locale == 'CN'?'newdetail flexcc font8rem black2':'newdetailEN flexcc font8rem black2'">
                {{ item.detail }}
              </div>
            </div>
          </div>
          <!-- <div class="swiper-slide">
            <div class="sbg">
              <div class="newpic flexcc">
                <img src="../assets/Images/new3_wxz.png" />
              </div>
              <div class="newname flexcc font12rem black">新零售</div>
              <div class="newdetail flexcc font8rem black2">
                一切围绕新零售贴着零售干
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="sbg">
              <div class="newpic flexcc">
                <img src="../assets/Images/new3.png" />
              </div>
              <div class="newname flexcc font12rem black">新能力</div>
              <div class="newdetail flexcc font8rem black2">
                快速解决问题的能力就是创新
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="sbg">
              <div class="newpic flexcc">
                <img src="../assets/Images/new5_wxz.png" />
              </div>
              <div class="newname flexcc font12rem black">新服务</div>
              <div class="newdetail flexcc font8rem black2">
                成就客户就是成就自我。言必行、行必果、己诺必诚
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="sbg">
              <div class="newpic flexcc">
                <img src="../assets/Images/new4_wxz.png" />
              </div>
              <div class="newname flexcc font12rem black">新技术</div>
              <div class="newdetail flexcc font8rem black2">
                平台化快速化业务场景化通用颗粒化技术
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import {WOW} from 'wowjs'
import Swiper from "swiper";
export default {
  name: "zj_serviceFeatures",
  data() {
    return {
      sfData:[{
          index: 1,
          name:this.$t("新思维") ,
          detail:this.$t("用户思维实现场景化,管理思维实现快速通用化") ,
          icon: require("../assets/Images/new1.png"),
          pic: require("../assets/Images/new1_xz.png"),
        },{
          index: 2,
          name:this.$t("新零售") ,
          detail:this.$t("一切围绕新零售贴着零售干") ,
          icon: require("../assets/Images/new3_wxz.png"),
          pic: require("../assets/Images/new3.png"),
        },{
          index: 3,
          name:this.$t("新能力") ,
          detail:this.$t( "快速解决问题的能力就是创新"),
          icon: require("../assets/Images/new2.png"),
          pic: require("../assets/Images/new2_xz.png"),
        },{
          index: 4,
          name:this.$t("新技术") ,
          detail:this.$t("平台化快速化业务场景化通用颗粒化技术") ,
          icon: require("../assets/Images/new4_wxz.png"),
          pic: require("../assets/Images/new4_xz.png"),
        },{
          index: 5,
          name:this.$t( "新服务"),
          detail:this.$t("成就客户就是成就自我。言必行、行必果、己诺必诚") ,
          icon: require("../assets/Images/new5_wxz.png"),
          pic: require("../assets/Images/new5_xz.png"),
        }]
    };
  },
  watch: {
    '$i18n.locale': function (newVal) {
         this.sfData=[{
          index: 1,
          name:this.$t("新思维") ,
          detail:this.$t("用户思维实现场景化,管理思维实现快速通用化") ,
          icon: require("../assets/Images/new1.png"),
          pic: require("../assets/Images/new1_xz.png"),
        },{
          index: 2,
          name:this.$t("新零售") ,
          detail:this.$t("一切围绕新零售贴着零售干") ,
          icon: require("../assets/Images/new3_wxz.png"),
          pic: require("../assets/Images/new3.png"),
        },{
          index: 3,
          name:this.$t("新能力") ,
          detail:this.$t( "快速解决问题的能力就是创新"),
          icon: require("../assets/Images/new2.png"),
          pic: require("../assets/Images/new2_xz.png"),
        },{
          index: 4,
          name:this.$t("新技术") ,
          detail:this.$t("平台化快速化业务场景化通用颗粒化技术") ,
          icon: require("../assets/Images/new4_wxz.png"),
          pic: require("../assets/Images/new4_xz.png"),
        },{
          index: 5,
          name:this.$t( "新服务"),
          detail:this.$t("成就客户就是成就自我。言必行、行必果、己诺必诚") ,
          icon: require("../assets/Images/new5_wxz.png"),
          pic: require("../assets/Images/new5_xz.png"),
        }]
     },
  },
  mounted() {
    new this.$wow.WOW({ live: false }).init();
            $(".fivenew_container .swiper-slide-active .newpic1").addClass("hidden");
            $(".fivenew_container.swiper-slide-active .newpic2").removeClass("hidden");
   
    this.swiperSan();
  },
  methods: {
    swiperSan() {
      
      let swiper1 = new Swiper(".fivenew_container", {
        direction: "horizontal",
        //autoplay:false,
        autoplay : 3000,
        autoplayDisableOnInteraction : false,
        //  autoplay: {
        //   delay: 3000,
        //   // stopOnLastSlide: false,
        //   disableOnInteraction: false,
        // },
        speed: 1000,
        autoplayDisableOnInteraction: false,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        loop: true,
        pagination: ".swiper-pagination",
        paginationClickable: true,
        grabCursor: true, //鼠标光标
        nextButton: ".swiper-button-next",
        prevButton: ".swiper-button-prev",
        centeredSlides: true,
        slidesPerView: 3,
        onSlideChangeStart: function(swiper){
      $(".fivenew_container .swiper-slide .newpic1").removeClass("hidden");
            $(".fivenew_container .swiper-slide .newpic2").addClass("hidden");
            $(".fivenew_container .swiper-slide-active .newpic1").addClass("hidden");
            $(".fivenew_container .swiper-slide-active .newpic2").removeClass("hidden");
    }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.zj_serviceFeatures {
  width: 100%;
  height: 100%;
  margin-top: 4rem;
  background-image: url("../assets/Images/linebg.png");
  background-repeat: no-repeat;
  background-size: 100%;
  .cnname {
    width: 100%;
  }
  .enname {
    width: 100%;
    margin: 1.5rem 0;
  }
  .nameline {
    width: 100%;
    .line {
      width: 8rem;
      height: 0.3rem;
      background-color: #2473e3;
      border-radius: 10px;
    }
  }
  .fivenew {
    width: 70%;
    height: 24rem;
    margin: 0 auto;
    margin-top: 2rem;
    .fivenew_container {
      width: 100%;
      height: 100%;
      .newpic {
        width: 100%;
        height: 50%;
        img {
          width: 25%;
        }
      }
      .newname {
        padding: 1.5rem 0;
      }
      .newdetail {
        width: 55%;
        text-align: center;
        margin: 0 auto;
      }
      .newdetailEN {
        width: 65%;
        text-align: center;
        margin: 0 auto;
      }
    }
    .sbg {
      width: 70%;
      height: 90%;
      margin: auto;
    }
    .fivenew_container .swiper-slide{
      padding-top:1.5rem;
    }
    .fivenew_container .swiper-slide-active .sbg {
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0 0 1rem rgba(67, 145, 233, 0.25);
    }
  }
}
</style>
