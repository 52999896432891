import VueI18n from "vue-i18n";
import cnLanguageMapfrom from './CN.js'
import enLanguageMapfrom from './EN.js'
import Vue from "vue";

Vue.use(
    VueI18n
)

const i18n = new VueI18n({
    locale: localStorage.getItem("language") ? localStorage.getItem("language") : "CN",
    // locale:  "CN",
    messages: {
        EN: {
            ...enLanguageMapfrom,
        },
        CN: {
            ...cnLanguageMapfrom,
        },
    },
});
  export default i18n