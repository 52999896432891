<template>
  <!--公司首页数据-版本2-->
  <div class="zj_codata flexrow">
    <div class="copart wow bounceIn" v-for="item in codata" :key="item.index" 
    data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1"
    >
      <div class="pic" :class="'pic' + item.index">
        <img :src="item.icon" />
      </div>
      <div class="name flexcc">{{ item.value }}{{ item.name }}</div>
    </div>
  </div>
</template>

<script>
import { WOW } from "wowjs";
export default {
  name: "codata",
  data() {
    return {
      codata: [
        {
          index: 1,
          name:this.$t("行业项目经验") ,
          value:this.$t("19年+") ,
          icon: require("../assets/Images/sy/xmjy_icon.png"),
        },
        {
          index: 2,
          name:this.$t("技术人员") ,
          value: this.$t("60+"),
          icon: require("../assets/Images/sy/ry_icon.png"),
        },
        {
          index: 3,
          name: this.$t("服务门店数"),
          value: this.$t("5W+"),
          icon: require("../assets/Images/sy/md_icon.png"),
        },
        {
          index: 4,
          name:this.$t("品牌客户") ,
          value: this.$t("70+"),
          icon: require("../assets/Images/sy/kh_icon.png"),
        },
        {
          index: 5,
          name: this.$t("合作大项目"),
          value: this.$t("50+"),
          icon: require("../assets/Images/sy/xm_icon.png"),
        },
      ],
    };
  },
  mounted() {
    new this.$wow.WOW({ live: false }).init();
  },
  watch: {
    // time(newval, oldval) {
    // },
     '$i18n.locale': function (newVal) {
        this.codata= [
        {
          index: 1,
          name:this.$t("行业项目经验") ,
          value:this.$t("19年+") ,
          icon: require("../assets/Images/sy/xmjy_icon.png"),
        },
        {
          index: 2,
          name:this.$t("技术人员") ,
          value: this.$t("60+"),
          icon: require("../assets/Images/sy/ry_icon.png"),
        },
        {
          index: 3,
          name: this.$t("服务门店数"),
          value: this.$t("5W+"),
          icon: require("../assets/Images/sy/md_icon.png"),
        },
        {
          index: 4,
          name:this.$t("品牌客户") ,
          value: this.$t("70+"),
          icon: require("../assets/Images/sy/kh_icon.png"),
        },
        {
          index: 5,
          name: this.$t("合作大项目"),
          value: this.$t("50+"),
          icon: require("../assets/Images/sy/xm_icon.png"),
        }
      ]
       }
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.zj_codata {
  width: 70%;
  height: 100%;
  margin: 0 auto;
  .copart {
    width: 20%;
    height: 100%;
    @keyframes co1 {
      0% {
        transform: translate(0, 0%);
        opacity: 1;
      }
      50% {
        transform: translate(0%, -50%);
        opacity: 1;
      }
      100% {
        transform: translate(0%, 0%);
        opacity: 1;
      }
    }
    @keyframes co2 {
      0% {
        transform: translate(0%, 0%);
        opacity: 1;
      }
      50% {
        transform: translate(-50%, 0%);
        opacity: 1;
      }
      100% {
        transform: translate(0%, 0%);
        opacity: 1;
      }
    }
    .pic1 {
      background-image: url("../assets/Images/sy/xmjy.png");
      background-position: center 100%;
      background-repeat: no-repeat;
      background-size: 40%;
      img {
        position: relative;
        margin-left: 45%;
        margin-top: 25%;
        width: 10%;
        animation: co1 3s infinite linear;
        -webkit-animation: co1 3s infinite linear;
      }
    }
    .pic2 {
      background-image: url("../assets/Images/sy/ry.png");
      background-position: center 100%;
      background-repeat: no-repeat;
      background-size: 40%;
      img {
        position: relative;
        margin-left: 43%;
        margin-top: 25%;
        width: 7%;
        animation: co2 3s infinite linear;
        -webkit-animation: co2 3s infinite linear;
      }
    }
    .pic3 {
      background-image: url("../assets/Images/sy/md.png");
      background-position: center 100%;
      background-repeat: no-repeat;
      background-size: 40%;
      img {
        position: relative;
        margin-left: 45%;
        margin-top: 25%;
        width: 10%;
        animation: co1 3s infinite linear;
        -webkit-animation: co1 3s infinite linear;
      }
    }
    .pic4 {
      background-image: url("../assets/Images/sy/kh.png");
      background-position: center 100%;
      background-repeat: no-repeat;
      background-size: 40%;
      img {
        position: relative;
        margin-left: 47%;
        margin-top: 20%;
        width: 7%;
        animation: co1 3s infinite linear;
        -webkit-animation: co1 3s infinite linear;
      }
    }
    .pic5 {
      background-image: url("../assets/Images/sy/xm.png");
      background-position: center 100%;
      background-repeat: no-repeat;
      background-size: 40%;
      img {
        position: relative;
        margin-left: 46%;
        margin-top: 15%;
        width: 8%;
        animation: co1 3s infinite linear;
        -webkit-animation: co1 3s infinite linear;
      }
    }
    .pic {
      width: 100%;
      height: 70%;
      img {
        // position: relative;
        // margin-left: 45%;
        // margin-top: 20%;
        // width: 10%;
      }
    }
    .name {
      width: 100%;
      height: 30%;
    }
  }
}
</style>
