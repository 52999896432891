import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import $ from 'jquery';
import store from './store';
import "./assets/css/common.css";
import wow from 'wowjs';
import 'animate.css';
import './assets/css/wowanimate.css';
import 'babel-polyfill';
import './assets/css/swiper.css';
import 'swiper/dist/css/swiper.css';
import 'core-js/stable';import 'regenerator-runtime/runtime';

import i18n from "./i18n/index.js";

// import Vant from 'vant';
// import 'vant/lib/index.css';
// import { Swipe, SwipeItem } from 'vant';
// import 'animate.css'

// import {WOW} from 'wowjs';

//new wow({live: false}).init();

// Vue.use(i18n);
Vue.prototype.ImgbaseURL = ''
Vue.prototype.url=''
Vue.prototype.bucketName =''
//Vue.prototype.ImgbaseURL = 'http://mcs.oneerp.com.cn'
//Vue.prototype.ImgbaseURL = 'http://www.oneerp.com.cn/'
import axios from 'axios'
Vue.prototype.$axios=axios;
Vue.prototype.$wow = wow
Vue.config.productionTip = false
axios.get("json/xxlUrl.json?random="+ new Date().getTime()).then(res=>{
   console.log('xxlUrlmain',res)

  if(res.data.url!= "" && res.data.url != undefined){
        Vue.prototype.url = res.data.url
  }else{
    Vue.prototype.url = "http://122.9.92.201:9090"
  }
 
Vue.prototype.ImgbaseURL = res.data.ImgbaseURL
Vue.prototype.bucketName = res.data.bucketName
})
router.afterEach((to,from,next) => {
  window.scrollTo(0,0);

})
new Vue({
  router,
  store,
  i18n,
  $,
  render: h => h(App)
}).$mount('#app')
