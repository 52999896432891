export default {
    // 顶部栏
    '首页':'Home page',
    '关于我们':'About us',
    '客户案例':'Customer case',
    '4000-620-530':'4000-620-530',
    //底部栏
    '电话联系':'Telephone contact',
    '江苏省苏州市常熟市高新技术产业开发区湖山路2号同济科技广场1幢14层':'14F, Building 1, Tongji Science and Technology Plaza, No.2 Hushan Road, High-tech Industrial Development Zone, Changshu City, Suzhou City, Jiangsu Province',
    '公司邮箱':'Company email',
    'Johnson.Cao@oneerp.com.cn':'Johnson.Cao@oneerp.com.cn',
    '扫一扫，了解更多':'Scan to learn more',
    '苏ICP备18048308号':'Su ICP No. 18048308',
    '© 魔元术(苏州)信息科技有限公司':'MAGIC MATE TOOL(SUZHOU) INFO.TECH.CO.LTD',
    '公司介绍':'Company introduction',
    '公司资质':'Company qualification',
    '核心团队':'Core team',
    '企业动态':'Enterprise dynamics',
    '产品展示':'Product display',
    '快速开发平台':'Rapid development platform',
    '新零售一体化系统':'New retail integration system',
    'POS+智慧收银系统':'POS+ Smart cash register system',
    '魔数智屏':'Magic Number intelligence screen',
    '零售数字化移动BI':'Digital mobile BI in retail',
    '全渠道营销与私域流量管理':'Omnichannel marketing and private traffic management',
    '服务案例':'Service case',
    '经典客户案例':'Classic customer case',
    '国内外知名品牌':'Well-known brands at home and abroad',
    '专业零售商':'Specialty retailer',
    // 首页
    '新零售数字化转型专家':'Expert in digital transformation of new retail',
    '驱动智慧零售':'Driving smart retail',
    '助力企业成长':'Help enterprises grow',
    '联系商务合作':'Contact Business cooperation',
    '联系我们':'Contact us',
    '联系电话':'Cintact number',
    '公司地址':'Company address',

    '行业项目经验':'Industry project experience',
    '19年+':'19 year+',
    '技术人员':'Technical personnal',
    '60+':'60+',
    '服务门店数':'Numbear of service stores',
    '5W+':'5W+',
    '品牌客户':'Brand customer',
    '50+':'50+',
    '70+': '70+',
    '合作大项目':'Major cooperative project',
    '20+':'20+',

    '魔元术于2018年7月成立，公司致力于打造中国新零售行业信息化最优质的生态圈，给所有的零售企业助力信息化建设。专注于为具备开发能力的鞋服配等企业提供智能化新零售一体化系统平台，通过分步骤初始化上线，让企业逐步拥有自身的平台并进行快速开发与扩展，以适应企业快速发展，从而打造企业零售信息化、智能化、科技化、数字化。': "MMT Established in July 2018, the company is committed to building the best quality ecosystem of information technology in China's new retail industry and helping all retail enterprises with information technology construction. Focusing on providing intelligent new retail integration system platform for enterprises with development capabilities such as shoes and clothing fittings, through the initial on-line step by step, so that enterprises gradually have their own platform and rapid development and expansion, in order to adapt to the rapid development of enterprises, so as to create enterprise retail information, intelligent, scientific and digital",
    '公司专业从事零售、服装行业、运动细分行业的互联网项目软件开发及建设，主要产品有新零售一体化系统、零售数字化移动BI及数据可视化大屏、快速开发平台工具以及全渠道营销与私域流量管理，帮助企业建立数字化竞争优势。': 'he company specializes in the development and construction of Internet project software for retail, clothing industry and sports subdivision industry. The main products include new retail integration system, retail digital mobile BI and data visualization large screen, rapid development platform tools, omni-channel marketing and private domain traffic management, helping enterprises to establish digital competitive advantages',
    '查':'check',
    '看更多':'Read more',

    '特色服务':'Special service',
    '新思维':'New thinking',
    '用户思维实现场景化,管理思维实现快速通用化':'User thinking to achieve scene, management thinking to achieve rapid generalization',
    '新零售':'New retail',
    '一切围绕新零售贴着零售干':'Everything revolves around new retail',
    '新能力':'New ability',
    '快速解决问题的能力就是创新':'The ability to solve problems quickly is innovation',
    '新技术':'New technology',
    '平台化快速化业务场景化通用颗粒化技术':'Platform-based fast business scenario-based general granulation technology',
    '新服务':'New service',
    '成就客户就是成就自我。言必行、行必果、己诺必诚':'Customer achieveent is self achievement.Word must be done, action must be done, have promised to be honest',

    '业务范围':'Bussiness scope',
    '新零售一体化':'New retail integration',
    '快速开发工具':'Rapid development tool',
    '智慧数据中台':'Intelligent Data Center',
    '全渠道营销':'Omni-channel marketing',
    '私域流量管理':'Private domain traffic management',
    '全渠道订单路由':'Omnichannel order routing',

    '晨光九木杂物社':'M&GSHOP',
    '魔元术新零售一体化系统':'MMT new retail integration system',
    '零售、批发、电商一体化':'Retail, wholesale, e-commerce integration',
    'POS、DRP、O2O订单路由、电商EB含OMS、移动BI等':'POS、DRP、O2OOrder routing、E-commerce EB includes OMS, mobile BI, etc',
    '多事业部/多品牌/多财务核算体':'Multi-division/Multi-brand/multi-financial accounting body',
    '终端POS、终端HR（考勤管理）、DRP、WMS、CRM（KK）、移动BI（作战监控）、EB含OMSDST&KL）':'Terminal POS, Terminal HR (attendance management), DRP, WMS, CRM (KK), Mobile BI (Combat monitoring), EB including OMSDST&KL)',
    '太平鸟':'PEACEBIRD',
    '多种形式POS、DRP、OMS、CRM、移动BI、新零售数据中台':'Various forms of POS, DRP, OMS, CRM, mobile BI, new retail data center',

    '强大的技术实力，持续引领市场':'Strong technical strength, continue to lead the market',
    '十九年的经验积累，产品精打细磨，客户口碑相传':'Nineteen years of experience accumulation, products fine grinding, customer word-of-mouth',
    '晨光':'M&G',
    '森马':'SEMIR',
    '雅诗兰黛':'ESTEELAUDER',
    '海棠铺子':'HAITANGPUZI',
    '戎美':'MADEINEARTH',
    '绿叶':'GREENLEFA',
    '木槿':'MUMUSO',
    '九木':'M&GSHOP',
    '热风':'HOTWIND',
    '可隆':'KOLON',
    '迪桑特':'DESCENTE',
    // 关于我们
    '行业领先新零售一体化系统及解决方案服务商':'New retail industryeading integrated system and solution provider',

    '魔元术公司于2018年7月在常熟成立，公司致力于打造中国新零售行业信息化最优质的生态圈，给所有的零售企业助力信息化建设。专注于为具备开发能力的鞋服配等企业提供智能化新零售一体化系统平台，通过分步骤初始化上线，让企业逐步拥有自身的平台并进行快速开发与扩展，以适应企业快速发展，从而打造企业零售信息化、智能化、科技化、数字化。':"MMT stablished in Changshu in July 2018, the company is committed to creating the best quality ecosystem of information technology in China's new retail industry and helping all retail enterprises with information technology construction. Focusing on providing intelligent new retail integration system platform for enterprises with development capabilities such as shoes and clothing fittings, through the initial on-line step by step, so that enterprises gradually have their own platform and rapid development and expansion, in order to adapt to the rapid development of enterprises, so as to create enterprise retail information, intelligent, scientific and digital.",
    '公司专业从事零售、服装行业、运动细分行业的移动互联网项目软件开发及建设，主要产品有新零售一体化系统、零售数字化移动BI及数据可视化大屏、快速开发平台工具以及全渠道营销与私域流量管理。通过运用大数据、人工智能等先进技术，对零售业商品的生产、流通与销售过程进行升级改造，帮助企业建立数字化竞争优势，达成渠道一体化、卖场智能化、商品社会化，打造经营数字化（主要包括营销数字化、门店数字化、供应链数字化、店员数字化、会员数字化、商品数字化的企业数字化进程问题）。':'The company specializes in the development and construction of mobile Internet project software for retail, clothing industry and sports subdivision industry. The main products include new retail integration system, retail digital mobile BI and data visualization large screen, rapid development platform tools, omni-channel marketing and private domain traffic management. Through the use of big data, artificial intelligence and other advanced technologies, the production, circulation and sales process of retail goods are upgraded and transformed, helping enterprises to establish digital competitive advantages, achieve channel integration, intelligent stores, and commodity socialization. Create a digital operation (mainly including marketing digitization, store digitization, supply chain digitization, shop assistant digitization, member digitization, commodity digitization of the enterprise digitization process).',
    '具有目前移动互联网行业内最先进的积木式、魔方式的快速配置、开发、交付能力，现已成为该行业内最大的模块化与开发软件的平台，彻底解决了零售批发电商一体化的软件问题。':"With the current mobile Internet industry's most advanced building blocks, magic mode of rapid configuration, development, delivery capabilities, has become the industry's largest modular and development software platform, completely solve the software problem of retail wholesale e-commerce integration",

    '5项资质':'5 qualifications',
    'CMMI3资质、IT产品信息安全认证':'CMMI3 qualification、IT product information security certification',
    'ISO9001、ISO20000、ISO27001':'ISO9001、ISO20000、ISO27001',
    '11项行业荣誉':'11 industry honor',
    '荣获 “中国时尚行业信息化技术与方案创新奖”':'Won the “China Fashion Industry Information Technology and Solution Innovation Award',
    '荣获“时尚商业信息化优秀IT供应商”':'Won the "Fashion Business Informatization Excellent IT Supplier"',
    '19项+政府荣誉称号':'19+Honorary title of government',
    '国家高新技术企业':'National high-tech enterprises',
    '国家科技型中小企业':'National science and technology small and medium-sized enterprises',
    '江苏省专精特新软件企业':'Jiangsu Province specialized in special new software enterprises',
    '获批姑苏创新创业领军人才计划':'Approved the Gusu Innovation and Entrepreneurship Leading Talent plan',
    '47个软件著作权':'47 transfer Copyrights',
    '新零售系统软件、移动新零售软件':'New retail system software, mobile new retail software',
    '数据中台、快速开发平台工具':'Data center, rapid development platform tools',

    '团队介绍':'Team introduction',
    '张学良':'Moon .Zhang',
    '创始人兼CEO':'Founder and CEO',
    '比利时列日大学硕士，中国管理科学研究院学术委员会特约研究员、姑苏科技创业领军人才、常熟市“科技创新创业领军人才”（A类），资深项目管理专家，国内首批获得PMP证书的专业项目管理人才，拥有18年以上零售行业开发和项目管理经验，国内“一个公司一套业务系统”提出者和缔造者,且是一体化先行与倡导者。获得过最佳管理、最佳项目、昆山市人才等多项荣誉，曾担任江苏省专家资源库专家，现为苏州市工信领域大数据行业专家。':'Master of University of Liege, Belgium, special researcher of Academic Committee of China Academy of Management Science, Leading talent of Science and Technology entrepreneurship in Gusu, "Leading Talent of Science and Technology Innovation and Entrepreneurship" in Changshu City (Class A), senior project management expert, the first professional project management talent to obtain PMP certificate in China, with more than 18 years of experience in retail industry development and project management. Domestic "one company one set of business system" proposer and founder, and is the integration of the first and advocate. He has won many honors such as best management, best project, Kunshan Talents, etc. He once served as an expert of Jiangsu Province expert resource database, and is now an expert of big data industry in Suzhou industry and information technology field',
    '宋之民':'Tony.Song',
    '毕业于北京大学信息管理与系统专业，资深平台专家，已有18年零售行业平台和定制经验。对平台抽象开发、零售业务开发以及接口抽象开发等有丰富的经验，有移动互联网行业丰富的流程、现场开发与团队开发管理经验。':'Graduated from Peking University majoring in Information Management and System, senior platform expert, has 18 years of experience in retail industry platform and customization. I have rich experience in platform abstract development, retail business development and interface abstract development, and rich experience in process, site development and team development management in the mobile Internet industry',
    '曹小强':'Johnson.Cao',
    '毕业于中国地质大学计算机应用专业，是中国较早从事计算机开发的人才,且是国内资深的数据库开发专家，对平台配置和优化有丰富的经验,有丰富的零售管理经验;具有19年鞋服行业信息化项目经验。':'Graduated from the computer application major of China University of Geosciences, is one of the earliest talents engaged in computer development in China, and is a senior database development expert in China, has rich experience in platform configuration and optimization, and has rich experience in retail management; 19 years of experience in footwear industry information projects',
    '吴昌成':'Alex.Wu',
    '开发总监':'Development director',
    '曾任盐城师范学院信息与计算科学专业老师，拥有13年的.NET开发经验,是开发创新型人才。精通.NET Framework框架、熟悉Entity Framework、MVC框架； 熟练掌握WCF；熟练应用RabbitMQ、Kafka等Q类组件应用技术,Redis、Memcached等缓存类组件应用技术 ；理解各种设计模式及其使用场景，精通多线程开发。':'He was a teacher of information and computing science in Yancheng Normal University for 13 years. NET development experience is the development of innovative talents. Proficient in.NET Framework, familiar with Entity Framework, MVC framework; Proficient in WCF; Proficient in Q component application technology such as RabbitMQ and Kafka, and cache component application technology such as Redis and Memcached; Understand various design patterns and their use cases, proficient in multi-threaded development',

    '公司动态':'Company dynamics',
    '月':'Month',
    '日':'Day',

    //产品展示
    '功能介绍':'Function introduction',
    '"一个公司、一套业务系统"':'One company, one business system',
    '魔元术POS+移动数字化管理解决方案是实现企业对所有终端门店及线上门店统一管控，提高实体收银、营销、会员经营、导购管理、线上线下融合等管理能力。帮助门店提升顾客体验与服务升级的精细化管理系统。':'MMT  POS+ mobile digital management solution is to realize the unified management and control of all terminal stores and online stores, and improve the management ability of physical cash register, marketing, member management, shopping guide management, online and offline integration. A refined management system that helps stores improve customer experience and service upgrades.',
    '打造品牌的超级门店   实现其全数字化经营':'Build a brand of super stores to achieve its full digital management',
    '重新定义数据可视化   提升客户数据洞悉能力':'Redefining data visualization improves customer data insights',
    '让企业关键经营数据尽在"掌"握':'Let the key business data of the enterprise be in the "palm"',
    '全渠道统一平台管理   保障各渠道无缝衔接协同':"Omni-channel unified platform management to ensure seamless coordination of all channels omni-channel unified platform management to ensure seamless coordination of all channels",

    '魔元术PAAS快速开发平台工具，结合魔元术19年零售行业经验沉淀，沉淀了上千种业务逻辑、将行业通用功能模块颗粒化、结合客户发展的策略和解决方案，以拖拉拽的方式，分步快速开发实现，让新零售系统更贴近业务，实现全价值链数据一体化，减少了传统系统的大量接口，数据不一致性，同时实现数据的实时性和整合性，实现分析与业务系统共存。':'MMT PAAS rapid development platform tool, combined with 19 years of experience in the retail industry, precipitated thousands of business logic, granulated common functional modules of the industry, combined with customer development strategies and solutions, in a drag and pull way, rapid development and implementation, so that the new retail system closer to the business, to achieve the whole value chain data integration, It reduces the large number of interfaces and data inconsistency of traditional system, and realizes the real-time and integration of data, realizing the coexistence of analysis and business system',
    '超过600个参数开发':'More than 600 parameters developed',
    '实现需求90%以上':'Achieve more than 90% of the requirements',

    '产品优势':'Product advantage',
    '开发效率高':'High development efficiency',
    '比传统定制开发效率快10倍左右，可省去程序前端页面、后台逻辑编写、程序集成等环节':'It is about 10 times faster than the traditional custom development efficiency, and can save the front-end page of the program, background logic writing, program integration and other links',
    '使用门槛低':'Low utility threshold',
    '无需浪费专业开发人员成本，只需配置开发的实施顾问即可操作':'No need to waste professional developer costs, just configure development implementation consultants to operate',
    '满足客户个性化要求':"Meet customer's individual requirements",
    '该工具可满足客户80%左右的需求，剩余20%个性化定制，充分满足客户业务流程的多样化要求':"The tool can meet about 80% of customers' needs, and the remaining 20% is customized to fully meet the diversified requirements of customers' business processes",
    '重塑性强':'Strong remodeling',
    '功能搭建自由灵活，保证满足零售企业随着市场不断的变化而变化':'Function construction is free and flexible to ensure that retail enterprises change with the continuous changes of the market',

    '解决行业发展痛点':'Solve the pain points of industry development',
    '多家软件组装拼接、数据不能互通，形成数据孤岛':'Multiple software assembly splicing, data can not be interoperable, forming data islands',
    '没有货通人通店通，数据价值差':'There is no goods through the people through the shop, the data value is poor',
    '可扩展性差，开发速度慢(基本都是定制型)':'Poor scalability, slow development (basically customized)',
    '查询数据不实时(移动化水平低)':'Query data is not real-time (low level of mobility)',

    '实现功能的集合，真正做到一体化，系统中包含了MDM、DRP、HR、POS、OA、WMS、CRM、FICO、EB以及一些传统接口功能，功能全面，解决了目前市场上其他家软件平台通过接口集成众多软件功能的问题。':'The system includes MDM, DRP, HR, POS, OA, WMS, CRM, FICO, EB and some traditional interface functions, with comprehensive functions, solving the problem of other software platforms on the market integrating many software functions through interfaces',
    '系统支持多语言，多财务组织、多HR组织结构图、多货币，较市面上软件逻辑处理更复杂。':'The system supports multi-language, multi-financial organization, multi-HR organization chart and multi-currency, which is more complex than the software logic processing in the market',
    '技术、产品、服务全面升级，重构新零售':'Comprehensive upgrade of technology, products and services, reconstruction of new retail',
    '痛点':'Pain point',
    '成效':'effect',
    '开发速度慢':'Slow development',
    '投入高':'High investment',
    '管理软件众多':'Numerous management software',
    '数据不互通':'Data interworking',
    '移动化水平低':'Low level of mobility',
    '操作复杂':'Complex operation',
    '可拓展性差':'Poor scalability',
    '10倍开发效率':'10x development efficiency',
    '节约50%前期投入':'Save 50% upfront investment',
    '功能一体化':'Functional integration',
    '数据互通':'Data interworking',
    '移动化水平高':'High level of mobility',
    '三步并一步，操作简单':'Simple operation',
    '支持嵌套二次开发，满足企业成长需求':'Support nested secondary development to meet the growth needs of enterprises',
    '助企业真正实现一体化管理':'Help enterprises truly realize integrated management',

    '魔元术新零售一体化系统-DRP':'MMT New retail integration system—DRP',
    '行业定制的门店巡店(IIS)、神秘访客(MSP)及日常管理评分，是在10多年零售行业及上百家零售业态中提炼出的管理规范。':'Industry-customized store tour (IIS), Mystery Visitor (MSP) and daily management scores are management norms distilled from the retail industry and hundreds of retail formats over 10 years',
    '门店支持PC端和手机端，结合系统软件及移动设备，全方面的提示传统管理模式，做到事半功倍。':'Store support PC and mobile terminal, combined with system software and mobile devices, all aspects of the traditional management mode, to achieve twice the result with half the effort',
    '可多个二维报表展示在一个页面上。':'Multiple 2D reports can be displayed on a single page',
    '魔元术新零售一体化系统-EB':'MMT New retail integration system——EB',
    'EB模块集合了电商、正向O2O,反向O2O，云仓等业务，是一整套 OMS 的订单中台，并且与POS前台紧密结合，实现库存共享和最佳发货路由。':'EB module integrates e-commerce, forward O2O, reverse O2O, cloud warehouse and other services. It is the order center of a complete set of OMS, and closely integrates with POS front desk to realize inventory sharing and optimal delivery routing',
    '一体化系统能更加保障库存数据的实效性。':'The integrated system can guarantee the effectiveness of inventory data',
    '魔元术新零售一体化系统-EB':'Magic Magic new retail integration system -EB',
    '支持各平台通过API获取到':'Support each platform through the API to obtain',
    'EB系统订单池':'EB system order pool',
    '人':'people',
    '公司':'Company',
    '货':'Cargo',
    '店':'Shop',
    '魔元术新零售一体化系统-MDM':'MMT New retail integration system-MDM',
    '支持多品牌事业部、多品牌商品运营、多品牌渠道管理、多品牌核算等':'Support multi-brand business units, multi-brand commodity operations, multi-brand channel management, multi-brand accounting, etc',
    '系统拥有400多个可设置开关型功能参数，灵活调整，打通人、货、店、公司，实现参数化，可自由配置。':'The system has more than 400 configurable switch type function parameters, which can be flexibly adjusted to open people, goods, stores and companies to achieve parameterization and free configuration',
    '魔元术新零售一体化系统-WMS':'MMT New retail integration system-WMS',
    '支持仓库锁定商品可下单数量、装车单纳入WMS管理、差异仓库管理、多货主多建筑体多类型多库区化库存共享管理、用户自定义组合拣货配置模式。':'Support warehouse locked goods can be ordered quantity, loading orders into WMS management, differential warehouse management, multi-owner multi-building multi-type multi-reservoir inventory sharing management, user-defined combination picking configuration mode',
    '魔元术新零售一体化系统-CRM':'MMT New retail integration system-CRM',
    '拥有用户画像管理、专属导购功能，丰富的自定义活动入会员积分兑换现金，现金翻倍；自定义发券规则。':'With user portrait management, exclusive shopping guide function, rich custom activities to exchange member points for cash, cash doubled; Custom coupon rules',
    '魔元术新零售一体化系统-FCIO':'MMT New retail integration syste-FCIO',
    '支持多财务组织、多货币、多种成本管理模式。':'Support multiple financial organizations, multiple currencies, multiple cost management models',
    '财务应收应付与前端业务紧密结合，深入业务的每一个环节，供应商、加盟商的对账，在线协调平台，实现内外财务数据共享。':'Financial receivables and payables are closely integrated with front-end business, going deep into every link of business, supplier and franchisee reconciliation, online coordination platform, to achieve internal and external financial data sharing',
    '集成税务接口,通过商场开票模块可直接上传发票信息。':'Integrated tax interface, through the shopping mall invoicing module can directly upload invoice information',
    '解决了零售行业多小、杂的困难':'It solves the difficulty of how small and miscellaneous the retail industry is',
    '打通了B2B和B2C业务流程':'Open B2B and B2C business processes',
    '将DRP和WMS无缝对接':'Interface DRP and WMS seamlessly',
    '去除了传统WMS的接口':'The interface of traditional WMS is removed',
    'Capillary(客提利)CRM':'Capillary(Ketilly)CRM',
    '达摩CRM':'Damo CRM',
    'HCR(慧辰资讯)CRM':'HCR(Huichen Information)CRM',
    '微软CRM':'Microsoft CRM',
    '数云CRM':'Digital cloud CRM',
    '微盟CRM':'Weimeng CRM',
    '枭荣CRM':'XIAORONG CRM',

    '新零售八大亮点':'Eight highlights of new retail',
    '全渠道':'omni-channel',
    '支持门店、电商、第三方平台等多种销售渠道，线上线下全线贯通':'Support stores, e-commerce, third-party platforms and other sales channels, online and offline',
    '一体化':'Integration',
    '一个数据中心，一体化管理，打通线上和线下，跨店跨区统一运营':'One data center, integrated management, open up online and offline, cross-store and cross-district unified operation',
    '大数据':'Big data',
    '所有门店、所有渠道数据实时同步，让企业掌握一手数据，实现大数据分析':'Real-time data synchronization of all stores and all channels allows enterprises to master first-hand data and achieve big data analysis',
    '强管控':'Strong control',
    '数据归集至总部，集团式企业管理，实现对店、对人、对用户的全面掌控':'Data collection to the headquarters, group enterprise management, to achieve the store, the people, the user of the full control',
    '高效管理':'Efficient management',
    '智能补货、OMS自动分单、多方配送等智能化管理方式，让管理更高效':'Intelligent management methods such as intelligent replenishment, OMS automatic ordering, and multi-party distribution make management more efficient',
    '精准营销':'Precision marketing',
    '三合一会员卡、二维码营销、LBS定位推荐等精准营销方式，快速吸粉':'Three-in-one membership card, two-dimensional code marketing, LBS positioning recommendation and other precision marketing methods, quickly attract fans',
    '智慧销售':'Smart selling',
    '不同门店销售不同商品，每个门店都能独立打造爆款，线上线下同步销售':'Different stores sell different goods, and each store can independently create explosive models and sell online and offline simultaneously',
    '灵活运营':'Flexible operation',
    '总部可统一运营，门店也能自己开展促销活动，支持送货上门、自提等多种配送方式':'Headquarters can be unified operation, stores can also carry out their own promotional activities, support door-to-door delivery, self-pickup and other distribution methods',

    '零售四宝':'Four retail treasures',
    '安全中心':'Safety center',
    '性能中心':'Performance center',
    '准确中心':'Exact center',

    'PAAS快速开发平台工具':'PAAS rapid development platform tool',
    '魔元术PAAS快速开发平台工具，结合魔元术19年零售行业经验沉淀，沉淀了上千种业务逻辑、将行业通用功能模块颗粒化、结合客户发展的策略和解决方案，以拖拉拽的方式，分步快速开发实现，让新零售系统更贴近业务，实现全价值链数据一体化，减少了传统系统的大量接口，数据不一致性，同时实现数据的实时性和整合性，实现分析与业务系统共存。':'MMT PAAS rapid development platform tool, combined with 19 years of experience in the retail industry, precipitated thousands of business logic, granulated common functional modules of the industry, combined with customer development strategies and solutions, in a drag and pull way, rapid development and implementation, so that the new retail system closer to the business, to achieve the whole value chain data integration, It reduces the large number of interfaces and data inconsistency of traditional system, and realizes the real-time and integration of data, realizing the coexistence of analysis and business system',
    '五大优势':'Five advantages',
    '全移动化场景':'Full mobile scene',
    '不受收银台PC硬件束缚，支持iOS和Android移动设备':'Free of cash register PC hardware, support iOS and Android mobile devices',
    '多维度分析':'Multidimensional analysis',
    '多维度指标，深层次挖掘数据价值，洞悉问题所在':'Multi-dimensional indicators, deep mining data value, insight into the problem',
    '智能预警':'Intelligent early warning',
    '多种AI预警功能，帮助管理层实时把控门店经营风险':' Avariety of AI warning functions help the management to control the risk of store operation in real time',
    '精细化运营':'Fine operation',
    '从消费者、购物体验、导购效率等各方面提升门店的数字化运营能力':'Improve the digital operation ability of stores from various aspects such as consumers, shopping experience and shopping guide efficiency',
    '打通数据多端同步，线上线下业务全覆盖，后台统一管理':'Open up multi-end data synchronization, full coverage of online and offline business, unified management of the background',

    '全方位数字化运营优势':'Full range of digital operation advantages',
    '快速化':'acceleration',
    '不受收银台PC硬件束缚，支持iOS和Android移动设备':'Free of cash register PC hardware, support iOS and Android mobile devices',
    '特有的去中心化计算，最大程度的利用了本地计算富余的计算能，节约了成本':'Unique decentralized computing, the maximum use of local computing surplus computing energy, saving costs',
    '整合五种形式POS，行业内独一性':'Integrate five forms of POS, unique in the industry',
    '扩展性强、支持多种接口':'Strong scalability, support for a variety of interfaces',
    '支持多语言、多币种':'Supports multiple languages and currencies',
    '支持多种收银模式，如现金、支付宝、微信支付等':'Support a variety of cash register modes, such as cash, Alipay, wechat Pay, etc',
    '支持离线模式，安卓、苹果、小程序等多种模式':'Support offline mode, Android, Apple, small program and other modes',
    '移动化':'Mobile informatization',
    '在线POS支持手机支付、小程序扫码购进行营销下单':'Online POS supports mobile payment and mini program code scanning for marketing orders',
    '接通微信支付，扫码购直接联网后台':'Connect wechat Pay, scan code purchase direct network background',
    '便捷化':'convenience',
    '安装便捷':'Easy to install',
    '操作便捷':'Easy to operate',
    '操作页面秉承“三步并一步”理念，简单明了，操作简捷':'Operation page adhering to the "three steps and one step" concept, simple and clear, simple operation',
    '参数自定义':'Parameter customization',
    '千店千面、操作页面自定义':'Thousands of stores, operation page custom',
    '业务化':'professionalization',
    '智能营销推荐':'Intelligent marketing recommendation',
    '自动计算最优促销，给客户推荐最优促销，提供最佳的购物体验':'Automatically calculate the best promotions, recommend the best promotions to customers, and provide the best shopping experience',
    '商场结算管理':'Department store settlement management',
    '商品及库存管控':'Merchandise and inventory control',
    '库存管控精细化（负库存管控严谨）':'Refined inventory control (strict negative inventory control)',
    '场景化':'scenization',
    '行业首创最优促销，可实现多种促销和卡券重合':"The industry's first optimal promotion, can achieve a variety of promotions and cards overlap",
    '促销功能多样化且具有创新性，支持市面上99%促销形式，活动和活动之间可叠加':'The promotional features are diverse and innovative, supporting 99% of the promotional forms on the market, and can be stacked between events',

    '全面提升数字化门店运营能力':'Comprehensively improve the operation capability of digital stores',
    '家门店':'store',
    '共同的选择':'Common choice',

    '魔数智屏——数据可视化大屏展示':'Magic number smart screen - data visualization large screen display',
    '与行业需求深度结合，围绕核心业务系统进行统一融合，按需调用，帮助运维人员提高运维监控和故障处置的效率。系统提供数据可视化和三维全景可视化服务，为用户提供一站式展示汇报方案，帮助汇报者更加直观和便捷的展示汇报，帮助决策者更好的发掘数据背后的规律，提高决策效率与能力。':'It is deeply integrated with industry requirements, integrates core business systems and invokes on demand to help operation and maintenance personnel improve the efficiency of operation and maintenance monitoring and fault handling. The system provides data visualization and three-dimensional panoramic visualization services, provides users with one-stop display and report solutions, helps the report more intuitive and convenient display and report, helps the decision maker better discover the law behind the data, and improves the decision-making efficiency and ability',
    '自2018年7月成立起，魔元术便开始对数据可视化大屏产品——魔数智屏做深入的研发，经过安踏集团、波司登集团、太平鸟集团等10多个大型客户的沉淀，以及一些中小型企业的打磨，结合魔元术研发团队十六年以上的行业信息化研发经验，千磨百炼，打造出行业适用性强、低代码敏捷化开发、拖拽式、多端（PC端、大屏端、移动端）互联控制、科技感十足的魔元术数据可视化大屏产品——魔数智屏。':'Since its establishment in July 2018, Moyuan Technology has begun to conduct in-depth research and development of data visualization large screen products - Monumber smart screen. After precipitation from more than 10 large customers such as Anta Group, Bosideng Group, and Taiping Bird Group, as well as grinding by some small and medium-sized enterprises, combined with more than 16 years of industry information research and development experience of Moyuan Technology R&D team, thousands of grinding and hundreds of refining. Create a strong industry applicability, low code agile development, drag and drop, multi-terminal (PC, large screen, mobile) Internet control, full of science and technology magic magic data visualization large screen products - Magic number smart screen',
    '魔数智屏与行业需求深度结合，围绕核心业务系统进行统一融合，按需调用，帮助运维人员提高运维监控和故障处置的效率。系统提供数据可视化和三维全景可视化服务，为用户提供一站式展示汇报方案，帮助汇报者更加直观和便捷的展示汇报，帮助决策者更好的发掘数据背后的规律，提高决策效率与能力。':'Magic Number intelligent screen is deeply integrated with industry needs, unified integration around the core business system, on-demand call, to help operation and maintenance personnel improve the efficiency of operation and maintenance monitoring and fault handling. The system provides data visualization and three-dimensional panoramic visualization services, provides users with one-stop display and report solutions, helps the report more intuitive and convenient display and report, helps the decision maker better discover the law behind the data, and improves the decision-making efficiency and ability',
    '行业痛点':'Industry pain point',
    '数据展示风格单一':'Single data presentation style',
    '产品体验不佳':'The product experience is not good',
    '视觉设计和交互能力弱':'Weak visual design and interaction skills',
    '数据反馈不及时':'Data feedback is not timely',
    '数据信息不互通':'The data information is not communicating',
    '决策管理无数据依据':'Decision management is not based on data',
    '魔数智屏——数据可视化配置工具':'Magic number smart screen - data visualization configuration tool',
    '作为数据可视化配置工具，可以让更多的人看到数据可视化的魅力，帮助非专业的工程师通过图形化的界面轻松搭建专业水准的可视化应用，满足用户会议展览、业务监控、风险预警、地理信息分析等多种业务的展示需求。':"As a data visualization configuration tool, it can let more people see the charm of data visualization, help non-professional engineers easily build professional visual applications through graphical interfaces, and meet the display needs of users' conferences and exhibitions, business monitoring, risk warning, geographic information analysis and other businesses",
    '魔元术根据“三最”原则——企业最关键、领导最关心、业务最贴近，定制了上百种样式精美的行业数据模板，用户可以从中选择最适合自己的方案模板，简单修改即可使用。以多层级的数据大屏、看板为载体，梳理数据流程、整合企业中的重要指标数据，形成管理的“闭环”，让数据在大屏、在企业流程中“转”起来！':'MMT According to the "three most" principle - the most critical enterprise, the most concerned leaders, the most close to the business, customized hundreds of exquisite style of industry data templates, users can choose the most suitable for their own scheme templates, simple modification can be used. With the multi-level data screen and Kanboard as the carrier, the data process is sorted out and the important indicator data in the enterprise is integrated to form a "closed loop" of management, and the data is "turned" in the big screen and the enterprise process',
    '1.丰富的数据可视化编辑工具':'Rich data visualization editing tools',
    '通过丰富的组件库和模板库，以及智能化的辅助手段，极大的降低非专业开发人员的搭建门槛。':'Through the rich component library and template library, as well as intelligent auxiliary means, greatly reduce the threshold of non-professional developers',
    '可视化拖拽界面，强大的编辑功能':'Visual drag and drop interface, powerful editing function',
    '提供多种业务模块级的非图表组件，所见即所得式的配置方式，只需要通过拖拉拽即可创造出专业的可视化应用':'Provides a variety of business module level non-graphical components, WYSIWYG configuration, just by dragging and dropping to create professional visual applications',
    '丰富的模板库':'Rich template library',
    '多种场景模版，简单修改即可快速投入使用。即便没有设计师，也可搭建专业大屏':'A variety of scene templates, simple modification can be quickly put into use. Even without a designer, you can build a professional screen',
    '丰富的组件库':'Rich component library',
    '提供多种业务模块级的非图表组件，所见即所得式的配置方式，只需要通过拖拉拽即可创造出专业的可视化应用':'Provides a variety of business module level non-graphical components, WYSIWYG configuration, just by dragging and dropping to create professional visual applications',
    '支持自定义组件':'Supports custom components',
    '提供开发者工具可以基于一定的组件规范，快速开发出自定义组件满足个性化需求。':'Provide developer tools to quickly develop custom components based on certain component specifications to meet individual requirements',
    '自定义组件存放在用户自己的组件库中，可供用户自由复用。':"Custom components are stored in the user's own component library and can be freely reused by users",
    '2.专业的地理信息可视化':'Professional geographic information visualization',
    '通过组件开发工具、编辑器等模块，帮助专业可视化开发人员灵活的进行组件开发和交互配置。':'Through component development tools, editors and other modules, help professional visual developers flexible component development and interactive configuration',
    '专业地理信息组件':'Professional geographic information components',
    '支持绘制地理轨迹、地理飞线、热力分布、3D地球等效果，可以一站式的完成空间地理数据相关的可视分析。':'It supports the drawing of geographical tracks, geographical flight lines, thermal distribution, 3D earth and other effects, and can complete the visual analysis of spatial geographic data in one stop',
    '低代码交互功能开发':'Low code interactive feature development',
    '通过蓝图编辑器来实现组件间的交互配置，复杂的交互逻辑可视化呈现，更易于维护。':'Through the blueprint editor to realize the interactive configuration of components, complex interaction logic visual presentation, easier to maintain',
    '3.大屏与移动BI互为一体，共同联动控制——移动BI配置工具':'Large screen and mobile BI are integrated into each other and jointly linked control - mobile BI configuration tool',
    '自助BI报表分析和制作可视化数据大屏的强大工具，通过拖拽图表组件可实现 5 分钟搭建数据可视化页面，组件丰富。通过可视化图表及强大的交互分析能力，为企业快速构建敏捷智能数据分析平台。':'A powerful tool for self-service BI report analysis and large screen visualization of data. By dragging and dropping chart components, you can build data visualization pages in 5 minutes. Through visualization charts and powerful interactive analysis capabilities, we can quickly build an agile intelligent data analysis platform for enterprises',
    '优势':'advantage',
    '新一代敏捷BI 让决策不再等数据':'The next generation of Agile BI makes decisions no longer wait for data',
    '丰富的功能和可视化图表模板，快速生成报表，满足企业数据分析和可视化展示需求':'Rich functions and visual chart templates to quickly generate reports to meet enterprise data analysis and visual display requirements',
    '简单好用易上手 快速完成数据分析报告':'Simple and easy to use to quickly complete the data analysis report',
    '产品使用门槛低，与数据源打通，拖拽点选、快速响应、即时生效、灵活调整，简单易上手':'Low product use threshold, open up with the data source, drag and drop, quick response, immediate effect, flexible adjustment, simple and easy to use',
    '极致美学设计、搭配高效的可视化技术':'The ultimate aesthetic design, with efficient visualization technology',
    '极致的数据美学，结合魔元术可视化技术，生动友好的呈现数据，同时提供丰富的报表模板，快速搭建设计师水准的数据报表':'The ultimate data aesthetics, combined with magic art visualization technology, vivid and friendly data presentation, while providing rich report templates, quickly build designer level data reports',
    '灵活多端，一次配置多端查看':'Flexible and multi-terminal, one configuration multi-terminal view ',
    '集成微信或企业微信，手机、PC、大屏等多种设备上自适应展示，随时随地查看分析可视化数据':'Integrate wechat or enterprise wechat, self-adaptive display on mobile phones, PCS, large screens and other devices, view and analyze visual data anytime and anywhere',
    'BI报表与大屏一体化':'BI report and large screen integration',
    '集BI报表与大屏功能于一体，满足客户不同场景的可视化需求':'It integrates BI report and large screen functions to meet the visualization needs of customers in different scenarios',
    '移动端完美适配':'Mobile perfectly fits',
    '布局自动适配移动端界面，随时随地查看分析可视化数据':'The layout automatically ADAPTS to the mobile interface to view and analyze visual data anytime and anywhere',
    '数据产品化  让数据分析思路可沉淀':'Data productization allows data analysis ideas to precipitate',
    '展现、分析、诊断、决策，从数据发现到数据决策，对特定业务数据进行全方位分析':'Present, analyze, diagnose, make decisions, from data discovery to data decisions, analyze all aspects of specific business data',
    'PC端所有大屏配置功能，移动端可完美复用，双端联控':'PC side all large screen configuration functions, mobile side can be perfectly reused, double-end joint control',
    'PC端所有大屏配置功能，均支持一键生成移动端报表，实现一次配置，多端查看。同时，大屏和移动端联动，可支持移动端远程控制大屏显示内容切换调整功能。':'All large-screen configuration functions on the PC can be used to generate mobile reports with one click, enabling one configuration and multiple viewing. At the same time, the large screen and the mobile terminal linkage, can support the mobile terminal remote control of the large screen display content switching adjustment function',
    '产品特征':'Product characteristics',
    '专业级大数据可视化':'Professional-grade big data visualization',
    '专精于地理信息与业务数据融合的可视化，提供丰富的行业模版和组件，支持自定义组件':'Specializes in the visualization of geographic information and business data integration, provides rich industry templates and components, and supports custom components',
    '实时高效的数据':'Real-time, efficient data',
    '支持从静态数据、CSV 文件、公网数据库，腾讯云数据库和 API 等多种数据源接入数据，通过配置可以动态实时更新':'Data can be accessed from static data, CSV files, public network databases, Teng cloud databases, and apis, and can be dynamically updated in real time through configuration',
    '拖拽式设计，在线自由布局设计':'Drag-and-drop design, online free layout design',
    '拖拽即可完成样式和数据配置，无需编程就能轻松搭建数据大屏':'Style and data configuration can be done by dragging and dropping, and it is easy to build large data screens without programming',
    '炫酷的3D展现与动画特效':'Cool 3D display and animation effects',
    '具有极强的视觉体验和冲击力，提供丰富3D图表、轮播动画、自动翻页、表格滚动等动画特效，通过简单拖拽方式即可实现3D动画、自动播放等多种特效，科技感十足，让大屏展示效果更具炫酷性。':'With a strong visual experience and impact, provide rich 3D charts, rotation animation, automatic page turning, table scrolling and other animation effects, through a simple drag and drop way to achieve 3D animation, automatic playback and other special effects, full of science and technology, so that the large screen display effect more cool',
    '移动端完美适配':'Mobile perfectly fits',
    '布局自动适配移动端界面，随时随地查看分析可视化数据':'Layout automatically ADAPTS to the mobile interface, view and analyze visual data anytime and anywhere',
    '灵活部署和推送':'Flexible deployment and push',
    '适配非常规拼接大屏，支持自定义推送，支持本地部署':'ADAPTS to unconventional splicing large screens, supports custom push, and supports local deployment',
    '案例一':'Case 1',
    '案例二':'Case 2',
    '案例三':'Case 3',
    '魔数智屏的优势':'The advantage of magic number smart screen',
    '更强的图表交互能力':'Enhanced chart interaction',
    '颜色预警、高亮联动、图表最大化、图表任意联动等多项功能，提供更好的图表交互能力。':'Color warning, highlighting linkage, chart maximization, chart arbitrary linkage and other functions provide better chart interaction',
    '更强的探索式分析能力':'More exploratory analytical skills',
    '通过报表间智能钻取与多维动态分析，真正实现探索式分析，从问题到原因，通过鼠标即可搞定！':'With intelligent drilling and multi-dimensional dynamic analysis between reports, truly exploratory analysis can be achieved, from problem to cause, with a mouse',
    '丰富精美的行业模板':'Rich and beautiful industry templates',
    '我们定制了几十种样式精美的行业数据模板，用户可以从中选择最适合自己的方案模板，简单修改即可使用。如果已有的模板无法满足你的需要，也可以联系我们做定制开发。':'We have customized dozens of exquisite industry data templates, users can choose the most suitable for their own scheme template, simple modification can be used. If the existing template does not meet your needs, you can also contact us for custom development',
    '强大的地理信息可视化':'Powerful visualization of geographic information',
    '在地理信息可视化方面你可以创建世界地图、全国地图和全国344个区县的地图，可以完成多层级视图(世界级视图、国家视图、省级视图等等)的切换操作，包括视图下钻与返回，还具有海量点图、热力图、动态连线等（此特性为echarts能力）。':'In terms of geographic information visualization, you can create the world map, the national map and the map of 344 districts and counties in the country, you can complete the switch operation of multi-level view (world-class view, national view, provincial view, etc.), including view drilling down and return, but also with massive point map, heat map, dynamic connection, etc. (this feature is echarts capability)',
    '灵活的适配和部署':'Flexible adaptation and deployment',
    '数据展现方案以网页形式发布，适配chrome、Edge等主流浏览器，还可以使用本地应用程序进行内嵌；移动端可以集成到微信、企业微信、钉钉中；通过定制调整可以支持适配各种尺寸、各种不规则形状的屏幕。':'The data presentation plan is published in the form of a web page, suitable for mainstream browsers such as chrome and Edge, and can also be embedded using local applications; The mobile terminal can be integrated into wechat, enterprise wechat and Dingtao; Through custom adjustment, it can support various sizes and various irregular shapes of the screen',
    '行业应用及应用场景':'Industry application and application scenarios',
    '1.实时汇报':'Real-time reporting',
    '交互式实时数据可视化大屏，实时监测企业数据，洞悉运营增长，助力智能高效决策。多源适配，可视化建模，配合强大灵活的数据分析能力，轻松实现秒级数据响应，实时发现异常。数据监控，安全预警，异常发现，多种实时场景，灵活适配：':'Large interactive real-time data visualization screen, real-time monitoring of enterprise data, insight into operational growth, help intelligent and efficient decision-making. Multi-source adaptation, visual modeling, combined with powerful and flexible data analysis capabilities, easy to achieve second-level data response, real-time anomaly detection. Data monitoring, security warning, anomaly discovery, a variety of real-time scenarios, flexible adaptation',
    '（1）多方位、多角度、全景展现企业的各项指标':'Multi-directional, multi-angle, panoramic display of enterprise indicators',
    '（2）数据实时监控，企业动态一目了然':'Real-time data monitoring, enterprise dynamics at a glance',
    '（3）个性化定制，布局灵活，样式丰富':'Personalized customization, flexible layout, rich style',
    '2.对内监管、激励':'Internal supervision and incentive',
    '数据可视化与业务紧密相连，可以让员工轻松的了解到工作的进度，让领导分析其业务表现，同时管理者可以根据此来改变整体业务的方式和方向，帮助分析绩效以及预测未来的绩效。通过分析，能够掌握到企业的动态，做到趋利避害：':'Data visualization is closely linked to the business, allowing employees to easily understand the progress of work, allowing leaders to analyze their business performance, and managers to change the overall business approach and direction according to this, helping to analyze performance and predict future performance. Through the analysis, we can grasp the dynamic of the enterprise, so as to seek advantages and avoid disadvantages',
    '（1）目标展示，鼓励业务人员完成目标':'Goal presentation, encourage business personnel to achieve goals',
    '（2）透明激励、横向对比竞争':'Transparent incentive, horizontal contrast competition',
    '（3）分析复盘，根据实际完成情况，提高管理者决策正确性':"Analyze the review, according to the actual completion situation, improve the correctness of the manager's decision",
    '3.对外宣传':'External publicity',
    '引入“面向对象”的场景构建方法，对不同的宣传对象在满足保密要求的前提下，实现宣传内容的精准投放，以发挥企业优势、提升客户满意度，有效推动企业品牌建设：':'The introduction of "object-oriented" scenario construction method, on the premise of meeting the confidentiality requirements of different publicity objects, to achieve accurate advertising content, in order to give play to the advantages of enterprises, improve customer satisfaction, and effectively promote the construction of corporate brands',
    '（1）直观掌握企业运营状态，及时获取企业数据':'Intuitive grasp of enterprise operation status, timely access to enterprise data',
    '（2）有效利用数据资产，充分发挥数据价值':'Make effective use of data assets and give full play to the value of data',
    '（3）一体化的数字化展示窗口，及时、高效的进行全域信息的数字化展示':'Integrated digital display window, timely and efficient digital display of global information',
    'BI报表与大屏一体化':'BI report and large screen integration',
    '集BI报表与大屏功能于一体，满足客户不同场景的可视化需求':'It integrates BI report and large screen functions to meet the visualization needs of customers in different scenarios',
    '移动端完美适配':'Mobile perfectly fits',
    '布局自动适配移动端界面，随时随地查看分析可视化数据':'The layout automatically ADAPTS to the mobile interface to view and analyze visual data anytime and anywhere',
    '数据产品化  让数据分析思路可沉淀':'Data productization allows data analysis ideas to precipitate',
    '展现、分析、诊断、决策，从数据发现到数据决策，对特定业务数据进行全方位分析':'Present, analyze, diagnose, and make decisions for all aspects of business-specific data, from data discovery to data decisions',
    'PC端所有大屏配置功能，移动端可完美复用，双端联控':'PC side all large screen configuration functions, mobile side can be perfectly reused, double-end joint control',
    'PC端所有大屏配置功能，均支持一键生成移动端报表，实现一次配置，多端查看。同时，大屏和移动端联动，可支持移动端远程控制大屏显示内容切换调整功能。':'All large-screen configuration functions on the PC can be used to generate mobile reports with one click, enabling one configuration and multiple viewing. At the same time, the large screen and the mobile terminal linkage, can support the mobile terminal remote control of the large screen display content switching adjustment function',
    '应用行业案例':'Application industry case',
    '客户案例一：波司登集团':'Customer Case 1: Bosideng Group',
    '波司登集团利用魔数智屏，基于自有的信息化系统，打造出了数据中台监控、供应链监控、产品业态分析、定制业务监控、O2O业务销售监控、车间实时监控、工单处理监控等。':'Bosideng Group uses magic Number smart screen, based on its own information system, to create data center monitoring, supply chain monitoring, product format analysis, customized business monitoring, O2O business sales monitoring, workshop real-time monitoring, work order processing monitoring, etc',
    '波司登店态数据中台大屏':'Bosideng shop-state data in large screen',
    '同一个大屏在不同店态的旗舰店的显示屏上，展示各自的营销动态，辅助店员及店长实时查看货品信息及销售状况，更具直观性及实时性。':'The same large screen displays their respective marketing dynamics on the display screens of flagship stores in different store formats, assisting shop assistants and store managers to view real-time product information and sales status, which is more intuitive and real-time',
    '波司登数据中台大屏':'Bosideng data center large screen',
    '主要用于管理层查询集团总体数据情况，支持多维度多角色数据查询，为管理层做决策起到数据支持性作用。':'It is mainly used for the management to query the overall data of the group, support multidimensional and multi-role data query, and play a data supporting role for the management to make decisions',
    '波司登O2O业务业务监控看板':'Bosideng O2O business business monitoring board',
    '整体通过数据与地图的集合从不同的展示视角为用户展示不同区域的数据情况，数据动态展示与地图紧密结合，实现了对O2O业务全方位实时监控，O2O业务进度一目了然，帮助业务部门实时跟进，促进用户对企业的决策与效率。':'Bose as a whole through the collection of data and maps from different display perspectives for users to show different areas of data, data dynamic display and map closely combined to achieve a full range of real-time monitoring of O2O business, O2O business progress is clear, help business departments to follow up in real time, and promote users to the decision and efficiency of the enterprise on the O2O business monitoring board',
    '注：以上所有数据均为模拟数据':'Note: All data above are simulated data',
    '客户案例二：太平鸟集团':'Customer Case 2: Peace Bird Group',
    '太平鸟2020年起使用魔数智屏产品，在企业形象墙上展示实时销售情况。大屏中对外销售数据经过脱敏处理，在有限的空间中表达庞大的信息量，精炼数据的同时保证数据的完整性，完美契合用户的菱形巨屏。当外部客户参观、领导视察时，菱形大屏的震撼效果彰显了公司的实力。':"Peacebird will use Magic Number smart screen products from 2020 to display real-time sales on the corporate image wall. In the large screen, the external sales data are desensitized to express a huge amount of information in a limited space, refine the data while ensuring the integrity of the data, and perfectly fit the user's diamond shaped giant screen. When external customers visit and leaders inspect, the shock effect of the diamond-shaped large screen highlights the strength of the company",
    '注：所有数据均为模拟数据':'Note: All data are simulated',
    '客户案例三：安踏集团':'Customer Case 3: Anta Group',
    '2019年起服务于安踏新零售大数据的可视化系列页面，基于魔元术自有新零售数据中台产品，将安踏的销售数据、会员数据、区域数据等进行监控查看。同时适配PC端操作习惯，提供用户日、周、月、年等条件筛选，完美集成展示大屏和PC看板各自优势，全方位、多层次地展示零售数据，有效提高了对数据管理的宏观掌控、实时监控，为安踏的后续性能、数据分析打好了良好的基础。':"Since 2019, it has served the visualization series page of Anta's new retail big data. Based on Moyuan's own new retail data middle platform products, Anta's sales data, member data, regional data, etc., will be monitored and viewed. At the same time, it ADAPTS to the operation habits of the PC side, provides users with conditions such as day, week, month, and year screening, perfectly integrates the advantages of the large screen and PC Kanban, displays retail data in an all-round and multi-level way, effectively improves the macro control and real-time monitoring of data management, and lays a good foundation for Anta's subsequent performance and data analysis",
    '注：以上所有数据均为模拟数据':'Note: All data above are simulated data',
    '客户案例四：SUNTOP（制造业）':'Customer Case 4: SUNTOP (Manufacturing)',
    'SUNTOP使用魔数智屏，根据自身硬件设备的传感器和监控，将数据汇总到魔数智屏后端，利用多屏轮播功能，直观的将总览信息、设备硬件信息、客流监控信息等数据整合在一起，还能顾及到数据的实时性，助力制造型企业“数字化转型”，提升管理体验。':'Using the magic number smart screen, according to the sensors and monitoring of their own hardware equipment, the data is summarized to the Magic number smart screen back end, using the multi-screen wheel casting function, intuitive overview information, equipment hardware information, passenger flow monitoring information and other data integration, but also take into account the real-time data, help manufacturing enterprises "digital transformation", improve the management experience',
    '注：所有数据均为模拟数据':'Note: All data are simulated',
    '持续创新赋能 助力品牌成长':'Continuous innovation enables brand growth',
    '十九年的技术和服务沉淀 成就知名品牌坚定选择':'Nineteen years of technology and service precipitation achievements of well-known brands firmly choose',
    '产品优势':'Product advantage',
    '提高决策效率':'Improve decision-making efficiency',
    '实时全面的数据交互分析，一目了然的业务数据展示，高效的团队分享协作':'Real-time and comprehensive data interaction analysis, business data display at a glance, efficient team sharing and collaboration',
    '丰富的可视化组件':'Rich visual components',
    '提供丰富的统计分析图表组件、Web组态图、地图等，以及行业特色化的场景仪表盘，满足各类数据展现需求':'It provides rich statistical analysis chart components, Web configuration diagrams, maps, and industry-specific scene dashboards to meet various data display requirements',
    '丰富的应用场景':'Rich application scenarios',
    '驾驶舱、宇宙、机场航班等场景，支持发布到多场景的数据大屏上进行可视化集中管控':'Cockpit, space, airport flights and other scenes, support the release of data to the large screen of multiple scenes for visual centralized control',
    '轻松认知数据':'Easy cognitive data',
    '数据双向互动，提供给用户对大数据可视化需求的无限助力与可能，让非专业用户也可以轻松自如地应对各项数据':'Two-way data interaction provides users with unlimited help and possibilities for big data visualization needs, so that non-professional users can also easily cope with various data',
    '助力企业决策':'Help enterprise decision making',
    '利用直观灵活多样的图表展示为企业提供业务驱动力，为企业提供决策支持。':'Provide business drivers and decision support for enterprises with intuitive and flexible graphical presentations',
    '多种统计方式' :'Multiple statistical methods',
    '求和、平均值、最大值、最小值、计数、不重复计数等多种统计方式，并可以对统计结果做数值格式处理':'Sum, average value, maximum value, minimum value, count, do not repeat count and other statistical methods, and the statistical results can be processed in numerical format',
    '共享数据便捷':'Easy to share date',
    '台式机、笔记本电脑的本地单一接口':'Single local interface for desktop and laptop computers',
    '响应快速':'Quick response',
    '支持动态局部刷新，秒级响应，各项操作流畅，即使数量巨大，用户也不必担心卡顿':'Support dynamic local refresh, second-level response, the operation is smooth, even if the number is huge, users do not have to worry about the lag',
    '产品特色':'Product features',
    '利用目前国际流行的前端UI包括canvas、Vue等':'Use the current international popular front-end UI including canvas, Vue and so on',
    '整合MMT特有的几百种新零售指标':'Integrate hundreds of new retail metrics unique to MMT',
    '丰富的2D/3D图库展现数据动态化、图形':'Rich 2D/3D gallery to show data dynamics, graphics',
    '后台利用大型数据库及相关算法进行计算':'The background uses a large database and related algorithms for calculation',
    '应用场景':'Application scenario',
    '战略指挥中心':'Strategic command center',
    '集团会议中心':'Group conference center',
    '实时监控中心':'Real-time monitoring center',
    '实现大数据的实时性和移动化，同时支持不同维度和角色的数据报表展现，随时随地查阅经营指标，快速准确获取所需的业务数据及分析展现，及时做出运营决策，从而更好地帮助企业进行业务管控。':'Realize real-time and mobile big data, while supporting the presentation of data reports of different dimensions and roles, check business indicators anytime and anywhere, quickly and accurately obtain required business data and analysis, and make timely operational decisions, so as to better help enterprises to conduct business management and control',
    '行业创新 五种形式POS':'',
    '数据驱动业务增长':'Data-driven business growth',
    '数据的几何级增长':'Geometric growth of data',
    '数据精益化运营':'Data lean operation',
    '各阶段关注指标':'Focus on indicators at each stage',
    '新兴的业务增长框架':'Emerging frameworks for business growth',
    '(海盗法则AARRR)':'(Law of Pirates AARRR)',
    '丰富的数据分析方法':'Rich data analysis methods',
    '钻取':'drilling',
    '向上钻取得到汇总数据向下钻取查看明细数据':'Drill up to get summary data and drill down to see detailed data',
    '联动':'linkage',
    '各个控件间相互关联，数据分析和结果更加明晰':'Each control is related to each other, and the data analysis and results are clearer',
    '预警':'forewarning',
    '针对数据源设置预警阀值，自动向客户端推送预警消息':'Set the alarm threshold for the data source and automatically push the alarm message to the client',
    '穿透':'Penetration',
    '可基于维度值进行穿透关联分析，便于逻辑查看':'Penetrative association analysis based on dimension values for logical viewing',
    '切片':'slice',
    '当维度值很多时，可选择其中一段重点分析':'If there are many dimensions, you can select one segment to focus on',
    '多维度检索':'Multidimensional retrieval',
    '全方位，多角度对不同指标进行对比分析':'All-round, multi-angle comparative analysis of different indicators',
    '应用价值':'Application value',
    '人':'People',
    '满足不同的管理角色移动办公、数字化决策需求':'Meet the needs of different management roles, mobile office, digital decision-making',
    '货':'Cargo',
    '商品实时查询分析，实现货品精细化管理，便于商品对门店库存把控':'Real-time query and analysis of goods, to achieve fine management of goods, to facilitate the control of goods on store inventory',
    '店':'Shop',
    '实时掌握门店运营态势，便于门店标准化管理，提升门店运营能力':'Grasp the operation situation of stores in real time, facilitate the standardized management of stores, and improve the operation ability of stores',
    '场':'Scene',
    '实时掌握企业运营态势，提升企业运营管理和风险管控水平，增强工作预见性':'Grasp the enterprise operation situation in real time, improve the level of enterprise operation management and risk control, and enhance the predictability of work',
    '打通全渠道 布局新零售':'Open up the all-channel layout of new retail',
    '会员通':'Member',
    '商品通':'Commodity',
    '服务通':'Service',
    '线上线下':'Online offline',
    '电商':'Electronic business',
    '实体':'Entity',
    '渠道':'Channel',
    '货品融合':'Goods fusion',
    '交易融合':'Transaction convergence',
    '渠道融合':'Channel integration',
    '体验融合':'Experience fusion',
    '同一盘货':'Same pallet',
    '同一订单':'Same order',
    '同一渠道':'Same channel',
    '同一支付':'Same payment',
    '货品通':'GS-Link',
    '订单通':'Order Link',
    '终端通':'Terminal pass',
    '财务通':'Financial expert',
    '线上线下一盘货，统一管理货品和库存':'Online and offline goods, unified management of goods and inventory',
    '业务管理互融一体':'Business management is integrated',
    '跨平台+多店铺':'Cross-platform + multi-store',
    '全渠道互通':'Omni-channel interoperability',
    '降解库存 提升销售':'Degrading inventory boosts sales',
    '统一微信生态账号体系':'Unify the wechat ecological account system',
    '全渠道多触点获客转化留存':'Omni-channel multi-touch customer conversion retention',
    '加客户至企微':'Add customers to enterprise micro',
    '获客留存':'Retained by a client',
    '减少流失':'Reduce wastage',
    '客户维护':'Customer maintenance',
    '客户标签':'Customer label',
    '一对一服务':'One-to-one service',
    '触达引流':'Tactile drainage',
    '用户画像':'User portrait',
    '营销自动化':'Marketing automation',
    '批发':'wholesale',
    '零售':'Retail',
    '电商':'Electronic business',
    '用户服务':'User service',
    '复购提醒':'Repurchase reminder',
    '流失回访':'Loss return visit',
    '售后提醒':'After-sales reminder',
    '沟通转化':'Communication transformation',
    '打标签':'Tag',
    '看画像1V1':'Tag',
    '猜Ta喜欢':'Guess what they like',
    '消费记录':'Consumption record',
    '用户画像':'User portrait',
    '扫码购':'Scan code purchase',
    '专属导购在线营销服务':'Exclusive shopping guide online marketing services',
    '门店发货':'Store delivery',
    '移动端发货':'Mobile delivery',
    '确认需求及购买意愿':'Identify demand and purchase intention',
    '分享商品卡片':'Share product card',
    '支付结算':'Payment and settlement',
    '优惠券/优惠活动查看':'Check out coupons/offers',
    '加入购物车':'Add to cart',
    '查看商品详情':'View product details',
    '物流追踪':'Logistics tracking',
    // 客户案例
    '驱动智慧零售 助力企业成长':'Drive smart retail to help enterprises grow',
    '为每一个客户提供行业性、定制化的解决方案':'Provide industry-specific and customized solutions for every customer',
    '安踏':'ANTA',
    '为其提供终端POS、终端HR(考勤管理)、DRP、WMS、CRM（KK）、移动BI（作战监控）、EB含OMS（DST&KL）服务，服务品牌有FILA、DESCENTE、KOLON、Sprandi、小笑牛，门店数量超过10000家。将其多个品牌的管理整合在一套系统中，提升了其50%以上的录单效率，100多种业务场景的应用激发销售者参与感，提升工作人员积极性和服务价值，实现多品牌多财务核算体，报表查询效率明显提升。':'Provide terminal POS, terminal HR(attendance management), DRP, WMS, CRM (KK), mobile BI (combat monitoring), EB including OMS (DST&KL) services, service brands include FILA, DESCENTE, KOLON, Sprandi, Xiaoxiaoniu, and the number of stores is more than 10,000. The management of its multiple brands is integrated in a system, which improves the booking efficiency of more than 50%, the application of more than 100 business scenarios stimulates the sense of participation of sellers, improves the enthusiasm of staff and service value, realizes multi-brand and multi-financial accounting body, and the efficiency of report query is significantly improved',
    '晨光九木杂物社是晨光集团下一家中高端文创杂货集合店，全国共有374 家（直营249 家，加盟125 家）。为其提供POS+、DRP、O2O订单路由、电商EB含OMS、移动BI等服务，彻底解决了原先系统运行不稳定、报错率高、操作繁琐等定性和程序健壮因素问题、POS结算效率提升60%以上、ERP报表查询速度控制在了5秒以内，大大提升了其报表查询效率、同时开发及交付效率提升了10倍左右。':'M&GSHOP is the next medium and high-end cultural and creative grocery collection store of Chenguang Group, with a total of 374 stores nationwide (249 directly operated, 125 joined). Provide POS+, DRP, O2O order routing, e-commerce EB including OMS, mobile BI and other services, completely solve the original system unstable operation, high error rate, cumbersome operation and other qualitative and robust program problems, POS settlement efficiency increased by more than 60%, ERP report query speed controlled within 5 seconds. Greatly improve its report query efficiency, while the development and delivery efficiency increased by about 10 times',
    '太平鸟':'PEACEBIRD',
    '为其提供多种形式POS、DRP、OMS、CRM、移动BI以及新零售数据中台服务，服务门店超过6000家，提升其门店录单效率60%以上，开发效率是一般工具的10倍左右。':'It provides various forms of POS, DRP, OMS, CRM, mobile BI and new retail data center services, serving more than 6,000 stores, improving the order booking efficiency of its stores by more than 60%, and the development efficiency is about 10 times that of general tools.',
    '波司登':'BOSIDENG',
    '为其提供业务数据中台，移动化项目外包开发服务，数据中台实现云平台、生产、电商、SAP以及HANA数据的大整理，报表开发效率是一般工具8倍，投入是SAP价格的5%。移动化实现了审批、数据化分析包括层级分析、沙盘演示等、以及开关店报店装修工程等移动化管理，我们的开发工具不仅提升了波司登集团的开发效率，还大大节约了其企业信息化的成本。':'Provide business data center, mobile project outsourcing development services, data center to achieve cloud platform, production, e-commerce, SAP and HANA data collation, report development efficiency is 8 times that of general tools, investment is 5% of SAP price. Mobile implementation of approval, data analysis, including hierarchical analysis, sandtable demonstration, and other mobile management, as well as the switch shop report shop decoration project, our development tools not only improve the development efficiency of Bosideng Group, but also greatly save the cost of enterprise informatization.',
    '助力上千家知名品牌零售商':'Help thousands of well-known brand retailers',
    '连接品牌、商户和消费者':'Connect brands, merchants and consumers',
};
