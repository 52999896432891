<template>
<!--首页头部-->
  <div class="topIntroduce">
    <div class="top_show flexrow">
      <div class="show_left wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
        <div class="show_title white font26rem flextbc color1">
        {{ $t('新零售数字化转型专家') }}  
        </div>
        <div class="show_mean white font14rem flextbc">
         {{$t('驱动智慧零售')}} &nbsp;&nbsp;&nbsp;{{$t('助力企业成长')}}
        </div>
        <div class="show_contact">
          <div class="btn_contact flexcc blue font10rem fontweight" @click="showPopup">
           {{$t('联系商务合作')}} 
          </div>
        </div>
      </div>
      <div class="show_right flexcc wow bounceInRight"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
        <div class="right_cube"></div>
      </div>
    </div>
   <div class="popup" v-show="show==true">
      <ContactPopUp @closePopup="closePopup"></ContactPopUp>
    </div>
  </div>
</template>

<script>
import ContactPopUp from "@/components/ContactPopUp.vue";
export default {
  name: "topIntroduce",
  props: ["time"],
  data() {
    return {
      timeData: [
        { id: "home", name: "首页" },
        { id: "about", name: "关于我们" },
        { id: "product", name: "产品展示" },
        { id: "custom", name: "客户案例" },
        { id: "contact", name: "联系我们" },
      ],
      selected: "home",
      isFixed: true,
      show:false
    };
  },
   components: {ContactPopUp},
  mounted() {
     new this.$wow.WOW({ live: false }).init();
    this.changeTab();
  //  window.addEventListener("scroll", this.handleScroll); // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
  },
  watch: {
    time(newval, oldval) {
      console.log("newval", newval);
      console.log("oldval", oldval);
      $(".ui_tab_item:first-child ").removeClass("ui_tab_item_on");
      this.selected = newval;
    },
  },
  methods: {
     closePopup(style) {
      console.log(style, "childByValue父");
      this.show = style;
    },
    showPopup() {
      this.show = true;
    },
    tabContentSel(id, e) {
      console.log(id);
      $(e).addClass("ui_tab_item_on").siblings().removeClass("ui_tab_item_on");
    },
    toFather(time) {
      this.$emit("fatherMethod", time);
    },
    changeTab() {
      $(".ui_tab_item").click(function () {
        $(this)
          .addClass("ui_tab_item_on")
          .siblings()
          .removeClass("ui_tab_item_on");
      });
    },
    // handleScroll() {
    //   let scrollTop =
    //     window.pageYOffset ||
    //     document.documentElement.scrollTop ||
    //     document.body.scrollTop; // 滚动条偏移量
    //   let offsetTop = document.querySelector(".topIntroduce").offsetTop; // 要滚动到顶部吸附的元素的偏移量
    //   this.isFixed = scrollTop > offsetTop ? true : false; // 如果滚动到顶部了，this.isFixed就为true
    //   console.log(11111);
    // },
  },
};
</script>

<style scoped lang="scss">

.popup{
  width: 100%;
  height: 200%;
  position: fixed;
  background-color: rgba(0,0,0,0.2);
  top:-37.5rem;
  z-index: 100;
  padding: 1px;
}
.top_show {
  width: 100%;
  height: 100%;
  background-image: url("../assets/Images/tbg1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.top_show .show_left {
  width: 50%;
  height: 100%;
}
.top_show .show_left .show_title {
  width: 80%;
  height: 20%;
  margin-top: 25%;
  padding-left: 3%;
  margin-left: 13%;
  background-image: url("../assets/Images/react.png");
  background-position: 0% center;
  background-repeat: no-repeat;
  background-size: 100%;
}
.top_show .show_left .show_mean {
  width: 80%;
  height: 20%;
  padding-left: 3%;
  margin-left: 13%;
}
.top_show .show_left .show_contact {
  width: 80%;
  height: 20%;
  padding-left: 3%;
  margin-left: 13%;
}
.top_show .show_left .show_contact .btn_contact {
  background-color: #fff;
  border-radius: 8px;
  width: 25%;
  height: 40%;
  cursor: pointer;
}
.top_show .show_right {
  width: 50%;
  height: 100%;
  background-image: url("../assets/Images/cubebg.png");
  background-position: center 70%;
  background-repeat: no-repeat;
  background-size: 50%;
}

@keyframes movepoint {
 0% {
        transform: translate(0%, 0%);
        opacity: 1;
      }
      50% {
        transform: translate(0%, -30%);
        opacity: 1;
      }
      100% {
        transform: translate(0%, 0%);
        opacity: 1;
      }
}
.top_show .show_right .right_cube {
  width: 25%;
  height: 50%;
  background-image: url("../assets/Images/cube.png");
  background-position: 70% 70%;
  background-repeat: no-repeat;
  background-size: 80%;
  //position:absolute;
  animation: movepoint 4s infinite linear;
  -webkit-animation: movepoint 4s infinite linear;
}
</style>
