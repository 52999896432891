<template>
  <!--联系我们弹窗-->
  <div :class=" $i18n.locale == 'CN'?'ContactPopUp':'ContactPopUpEN'">
    <div class="title font12rem fontweight flexlrbetween">
      <span class="span1"></span>
      <span class="span2 flexcc blue1">{{$t('联系我们')}}</span>
      <span class="span1 flexcc spanx black2" @click="closePopup">×</span>
    </div>
    <div class="co1 flexlrbetween bottom_line">
      <div class="t1 flextbc font10rem">
        <img src="../assets/Images/pho.png" />{{$t('联系电话')}}
      </div>
      <div class="t2 black2 flextbc font10rem flexlrend">{{$t('4000-620-530')}}</div>
    </div>
    <div class="co1 flexlrbetween bottom_line">
      <div class="t1 flextbc font10rem">
        <img src="../assets/Images/mai.png" />{{$t('公司邮箱')}}
      </div>
      <div class="t2 black2 flextbc font10rem flexlrend">{{$t('Johnson.Cao@oneerp.com.cn')}}</div>
    </div>
    <div class="co1 flexlrbetween">
      <div class="t1 flextbc font10rem">
        <img src="../assets/Images/loc.png" />{{$t('公司地址')}}
      </div>
      <div class="t2 black2 flextbc font10rem flexlrend">
        {{$t('江苏省苏州市常熟市高新技术产业开发区湖山路2号同济科技广场1幢14层')}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactPopUp",
  props: [],
  data() {
    return {
      pic: require("../assets/Images/ustbg.png"),
      selected: "home",
      isFixed: true,
      show: false,
    };
  },
  mounted() {},
  watch: {},
  methods: {
    closePopup() {
      this.show = false;
      this.$emit("closePopup", this.show);
    },
  },
};
</script>

<style scoped lang="scss">
.ContactPopUp {
  width: 30%;
  height: 20%;
  border-radius: 1rem;
  // margin: auto;
  margin: 50% auto;
  background-color: #fff;
  .bottom_line {
    border-bottom: 1px solid rgba(172, 172, 172, 0.2);
  }
  .title {
    width: 100%;
    height: 20%;
    .span1 {
      width: 20%;
      height: 100%;
    }
    .span2 {
      width: 80%;
      height: 100%;
    }
    .spanx {
      cursor: pointer;
    }
  }
  .co1 {
    width: 80%;
    height: 23%;
    margin: 0 auto;
    .t1 {
      width: 30%;
      height: 100%;
      img {
        width: 16%;
        margin-right: 5%;
      }
    }
    .t2 {
      width: 70%;
      height: 100%;
      text-align: right;
    }
  }
}
.ContactPopUpEN {
  width: 30%;
  height: 30%;
  border-radius: 1rem;
  // margin: auto;
  margin: 50% auto;
  background-color: #fff;
  .bottom_line {
    border-bottom: 1px solid rgba(172, 172, 172, 0.2);
  }
  .title {
    width: 100%;
    height: 20%;
    .span1 {
      width: 20%;
      height: 100%;
    }
    .span2 {
      width: 80%;
      height: 100%;
    }
    .spanx {
      cursor: pointer;
    }
  }
  .co1 {
    width: 80%;
    height: 23%;
    margin: 0 auto;
    .t1 {
      width: 30%;
      height: 100%;
      img {
        width: 16%;
        margin-right: 5%;
      }
    }
    .t2 {
      width: 70%;
      height: 100%;
      text-align: right;
    }
  }
}
.van-popup {
  border-radius: 10px !important;
}
</style>
