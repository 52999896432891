<template>
    <!--底部通用-->
    <div class="zj_bottomIntroduce">
        <div class="info flexrow">
            <div class="contact">
                <div class="pic flexcc">
                    <img src="../assets/Images/phone.png" />
                </div>
                <div class="name flexcc font10rem grey">
                    {{ $t("电话联系") }}
                </div>
                <div class="detail flexcc font8rem">
                    {{ $t("4000-620-530") }}
                </div>
            </div>
            <div class="address">
                <div class="pic flexcc">
                    <img src="../assets/Images/location.png" />
                </div>
                <div class="name flexcc font10rem grey">
                    {{ $t("公司地址") }}
                </div>
                <div class="detail flexcc font8rem font8remBF">
                    {{
                        $t(
                            "江苏省苏州市常熟市高新技术产业开发区湖山路2号同济科技广场1幢14层"
                        )
                    }}
                </div>
            </div>
            <div class="mail">
                <div class="pic flexcc">
                    <img src="../assets/Images/mail.png" />
                </div>
                <div class="name flexcc font10rem grey">
                    {{ $t("公司邮箱") }}
                </div>
                <div class="detail flexcc font8rem">
                    {{ $t("Johnson.Cao@oneerp.com.cn") }}
                </div>
            </div>
        </div>
        <div class="bottom flexrow">
            <div class="left">
                <div
                    :class="
                        $i18n.locale == 'CN' ? 'logo flexcc' : 'logoEN flexcc'
                    "
                >
                    <img
                        :src="
                            $i18n.locale == 'CN'
                                ? require('@/assets/Images/logo.png')
                                : require('@/assets/Images/logoEN.png')
                        "
                    />
                </div>
                <div class="qrcode flexcc">
                    <img src="../assets/Images/qrcode.png" />
                </div>
                <div class="sys flexcc white font8rem">
                    {{ $t("扫一扫，了解更多") }}
                </div>
                <div class="bh flexcc">
                    <a
                        target="_blank"
                        href="https://beian.miit.gov.cn/"
                        class="white"
                        >{{ $t("苏ICP备18048308号") }}</a
                    >
                </div>
                <div class="company flexcc white2 font8rem">
                    {{ $t("© 魔元术(苏州)信息科技有限公司") }}
                </div>
            </div>
            <div class="right flexrow">
                <div class="home">
                    <div
                        class="title white font10rem pointer"
                        @click="goto('home')"
                    >
                        {{ $t("首页") }}
                    </div>
                </div>
                <div class="aboutus">
                    <div
                        class="title white font10rem pointer"
                        @click="goto('aboutUs')"
                    >
                        {{ $t("关于我们") }}
                    </div>
                    <div
                        class="name white2 font8rem pointer"
                        @click="gomd('/aboutUs', 'companyIntroduce', '')"
                    >
                        {{ $t("公司介绍") }}
                    </div>
                    <!-- <div class="name white2 font8rem pointer" @click="gomd('/aboutUs','companyQualification')">发展历程</div> -->
                    <div
                        class="name white2 font8rem pointer"
                        @click="gomd('/aboutUs', 'companyQualification', '')"
                    >
                        {{ $t("公司资质") }}
                    </div>
                    <div
                        class="name white2 font8rem pointer"
                        @click="gomd('/aboutUs', 'teamIntroduce', '')"
                    >
                        {{ $t("核心团队") }}
                    </div>
                    <div
                        class="name white2 font8rem pointer"
                        @click="gomd('/aboutUs', 'companyNews', '')"
                    >
                        {{ $t("企业动态") }}
                    </div>
                </div>
                <div :class="$i18n.locale == 'CN'?'product':'productEN'">
                    <div
                        class="title white font10rem pointer"
                        @click="goto('product')"
                    >
                        {{ $t("产品展示") }}
                    </div>
                    <div
                        class="name white2 font8rem pointer"
                        @click="gomd('/productPass', 'productTab', '0')"
                    >
                        {{ $t("快速开发平台") }}
                    </div>
                    <div
                        class="name white2 font8rem pointer"
                        @click="gomd('/productRetail', 'productTab', '1')"
                    >
                        {{ $t("新零售一体化系统") }}
                    </div>
                    <div
                        class="name white2 font8rem pointer"
                        @click="gomd('/productPos', 'productTab', '2')"
                    >
                        {{ $t("POS+智慧收银系统") }}
                    </div>
                    <div
                        class="name white2 font8rem pointer"
                        @click="gomd('/detialDesign', 'productTab', '3')"
                    >
                        {{ $t("魔数智屏") }}
                    </div>
                    <div
                        class="name white2 font8rem pointer"
                        @click="gomd('/productBi', 'productTab', '4')"
                    >
                        {{ $t("零售数字化移动BI") }}
                    </div>
                    <div
                        class="name white2 font8rem pointer"
                        @click="gomd('/productMarket', 'productTab', '5')"
                    >
                        {{ $t("全渠道营销与私域流量管理") }}
                    </div>
                </div>
                <div class="cases">
                    <div
                        class="title white font10rem pointer"
                        @click="goto('custom')"
                    >
                        {{ $t("服务案例") }}
                    </div>
                    <div
                        class="name white2 font8rem pointer"
                        @click="gomd('/custom', 'classicCustom', '')"
                    >
                        {{ $t("经典客户案例") }}
                    </div>
                    <div
                        class="name white2 font8rem pointer"
                        @click="gomd('/custom', 'classiccustomerLogo', '')"
                    >
                        {{ $t("国内外知名品牌") }}
                    </div>
                    <div
                        class="name white2 font8rem pointer"
                        @click="gomd('/custom', 'classicDealer', '')"
                    >
                        {{ $t("专业零售商") }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "zj_bottomIntroduce",
    props: [],
    data() {
        return {};
    },
    mounted() {},
    watch: {
        time(newval, oldval) {
            console.log("newval", newval);
            console.log("oldval", oldval);
        },
    },
    methods: {
        goto(link) {
            this.$router.push(link);
        },
        gomd(page, selectID, id) {
            console.log(id, "childByValue");

            console.log(this.$route.path, "this.$route.path");
            console.log(page, "page");
            // page
            // if (page == this.$route.path) {  //如果当前已经在这个页面不跳转 直接去
            //   let toElement = document.getElementById(selectID);
            //   toElement.scrollIntoView(true);
            // } else {  //否则跳转路由
            //   localStorage.setItem("toId", selectID);
            //   this.$router.push({ path: page });
            // }

            localStorage.setItem("toId", id);
            if (page != this.$route.path) {
                //不在当前页面
                this.$router.push(page);
            }
            this.$nextTick(() => {
                let that = this;
                let toElement = document.getElementById(selectID);
                toElement.scrollIntoView(true);
                if (id != "") {
                    console.log("--------------");
                    that.$emit("childByValue", id);
                }
            });
        },
    },
};
</script>

<style scoped lang="scss">
.font8remBF {
    width: 105% !important;
}
.zj_bottomIntroduce {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    .info {
        width: 100%;
        height: 6rem;
        .contact,
        .address,
        .mail {
            width: 33.3%;
            height: 100%;
            img {
                width: 5%;
            }
        }
    }
    .bottom {
        background-color: #202731;
        width: 100%;
        height: 20rem;
        .left {
            width: 40%;
            height: 100%;
            .logo {
                width: 100%;
                height: 20%;
                img {
                    width: 22%;
                }
            }
            .logoEN {
                width: 100%;
                height: 20%;
                img {
                    width: 30%;
                }
            }
            .qrcode {
                width: 100%;
                height: 40%;
                img {
                    width: 22%;
                }
            }
            .sys {
                width: 100%;
                height: 15%;
            }
            .bh {
                width: 100%;
                height: 10%;
            }
            .company {
                width: 100%;
                height: 10%;
            }
        }
        .right {
            width: 40%;
            height: 100%;
            margin-left: 10%;
            .pointer {
                cursor: pointer;
            }
            .title {
                padding: 1rem 0;
            }
            .name {
                padding: 0.5rem 0;
            }
            .home {
                width: 25%;
                height: 100%;
            }
            .aboutus {
                width: 25%;
                height: 100%;
            }
            .product {
                width: 25%;
                height: 100%;
            }
            .productEN {
                width: 42%;
                height: 100%;
            }
            .cases {
                width: 25%;
                height: 100%;
            }
        }
    }
}
</style>
