<template>
<!--关于我们-->
  <div class="zj_aboutus flexrow">
    <div class="aboutus_pic wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1"></div>
    <div
      class="aboutus_info wow bounceInRight"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1"
      
    >
      <div class="us black font16rem">{{$t('关于我们')}}</div>
      <div class="usline"></div>
      <div :class="$i18n.locale == 'CN'?'usinfo1 font8rem black2':'usinfo1EN font8rem black2'">
        {{$t('魔元术于2018年7月成立，公司致力于打造中国新零售行业信息化最优质的生态圈，给所有的零售企业助力信息化建设。专注于为具备开发能力的鞋服配等企业提供智能化新零售一体化系统平台，通过分步骤初始化上线，让企业逐步拥有自身的平台并进行快速开发与扩展，以适应企业快速发展，从而打造企业零售信息化、智能化、科技化、数字化。')}}
      </div>
      <div :class="$i18n.locale == 'CN'?'usinfo2 font8rem black2':'usinfo2EN font8rem black2'">
       {{$t('公司专业从事零售、服装行业、运动细分行业的互联网项目软件开发及建设，主要产品有新零售一体化系统、零售数字化移动BI及数据可视化大屏、快速开发平台工具以及全渠道营销与私域流量管理，帮助企业建立数字化竞争优势。')}} 
      </div>
      <div class="more flextbc black2 font8rem" @click="clickTo">
        <span class="cha">{{$t('查')}}</span>
        <span>{{$t('看更多')}}</span>
        <span class="jt blue1 font16rem">→</span>
      </div>
    </div>
  </div>
</template>

<script>
import {WOW} from 'wowjs'
export default {
  name: "zj_aboutus",
  data() {
    return {};
  },
  watch: {
    // cases() {
    //   this.$nextTick(() => { // 在dom渲染完后,再执行动画
    //     var wow = new WOW({
    //       live: false
    //     })
    //     wow.init();
    //   })
    // }
  },
  mounted() {
    new this.$wow.WOW({ live: false }).init();
//     this.$router.afterEach((to,from,next) => {
//   window.scrollTo(0,0);

// })
  },
  methods: {
    clickTo() {
     // console.log("跳转至查看更多页面");
      this.$router.push("aboutUs");
    },
  },
};
</script>

<style scoped lang="scss">
.zj_aboutus {
  width: 100%;
  height: 100%;
  background-color: rgba(239, 246, 255, 0.5);
  .aboutus_pic {
    width: 50%;
    height: 100%;
    background-image: url("../assets/Images/uspic.png");
    background-position: left 50%;
    background-repeat: no-repeat;
    background-size: 80%;
  }
  .aboutus_info {
    width: 50%;
    height: 100%;
  }
  .us {
    margin-top: 10%;
  }
  .usline {
    width: 5rem;
    height: 0.3rem;
    background-color: #2473e3;
    border-radius: 10px;
    margin-top: 3%;
  }
  .usinfo1,
  .usinfo2 {
    width: 70%;
    line-height: 2rem;
    margin-top: 4%;
  }
  .usinfo1EN,
  .usinfo2EN {
    width: 70%;
    line-height: 1.5rem;
    margin-top: 4%;
  }
  .more {
    width: 100%;
    height: 5rem;
    margin-top: 4%;
    cursor: pointer;
    .cha {
      padding: 1rem 0 1rem 1rem;
      background-color: rgba(36, 115, 227, 0.1);
    }
    .jt {
      margin-left: 1rem;
    }
  }
}
</style>
